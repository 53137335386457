import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AutenticacaoService } from './service/autenticacao.service';
import { Login } from './model/login.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  version: string  = environment.VERSION;

  login: Login = { email: '', senha: ''};


  constructor(
    private autenticacaoService: AutenticacaoService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(){ }


  fazerLogin(){
    this.autenticacaoService
        .login(this.login.email, this.login.senha)
        .subscribe(
          (success) => this.router.navigate(['/mural']),
          (error) => this.toastr.error('Não foi possível realizar o login', 'Erro')
        );
  }

  revelarsenha() {
    let input = document.querySelector('#password');
    if (input?.getAttribute('type') == 'password') {
      input.setAttribute('type', 'text');

    } else {
      input?.setAttribute('type', 'password')
    }

  }
}
