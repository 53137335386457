import { AgendaCarousel } from './agenda-carousel.model';
import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[carouselItem]'
})
export class AgendaCarouselItemDirective {

  constructor( public tpl : TemplateRef<AgendaCarousel> ) {
  }

}
