import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnturmacaoService {

  constructor() { }

  getDadosEnturmacao(){
    return [
      {id: 1, nome: "Matheus Correa", turma: "3002", email: "matheus@email.com"},
      {id: 2, nome: "Marcos", turma: "3001", email: "marcos@email.com"}
    ]
  }
}
