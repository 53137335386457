import { AgendaAtividadesComponent } from './agenda-atividades/agenda-atividades.component';
import { AgendaCarouselComponent } from './agenda-carousel/agenda-carousel.component';
import { AgendaCalendarioComponent } from './agenda-calendario/agenda-calendario.component';
import { ProfileSchoolPackageService } from './../shared/service/profileSchoolPackage.service';
import { AutenticacaoService } from './../login/service/autenticacao.service';
import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AgendaCarousel } from './agenda-carousel/agenda-carousel.model';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent {
  currentMonth: number = (new Date().getMonth());
  selected!: Date;
  isLoading = true;
  innerWidth!: number;
  profileId: number = 0;
  weeks: any[] = [];
  @ViewChild('calendario') calendario!: AgendaCalendarioComponent;
  @ViewChild('semanas') semanas!: AgendaCarouselComponent;
  @ViewChild('atividades') atividades!: AgendaAtividadesComponent;

  items: AgendaCarousel[] = [
    { title: 'Janeiro'},
    { title: 'Fevereiro'},
    { title: 'Março'},
    { title: 'Abril'},
    { title: 'Maio'},
    { title: 'Junho'},
    { title: 'Julho'},
    { title: 'Agosto'},
    { title: 'Setembro'},
    { title: 'Outubro'},
    { title: 'Novembro'},
    { title: 'Dezembro'}
  ];

  constructor(
    private router: Router,
    private autenticacaoService: AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService
  ) {}

  ngOnInit(): void {
    this.currentMonth = (new Date().getMonth());
    this.isLoading = true;
    this.innerWidth = window.innerWidth;
    this.autenticacaoService
        .getLoggedUser()
        .subscribe( (u) => {
          // if(u?.role !== 'Students') {
          //   this.router.navigate(['/mural']);
          // }
          this.profileId = u?.profileId as number;
        });
  }

  setCalendarMonth(event: number) {
    this.calendario.setMonth(event);
    setTimeout( () => {
      this.semanas.currentSlide = 0;
      this.semanas.changeItemWidth();
    })
  }

  selectWeek(event: number) {
    setTimeout( () => {
      this.semanas.currentSlide = event;
      this.semanas.changeItemWidth();
      this.setCalendarWeek(event);
    })
  }

  setCalendarWeek(event: number) {
    setTimeout( () => {
      this.atividades.currentWeek = event;
      this.atividades.setWeekActivities();
    });
  }

}
