import { Component, OnInit } from '@angular/core';
import { faUserCircle, faTasks, faBars, faChartLine, faListAlt, faBook, faHeadset, faCheck, faUser, faCalendar} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  faBook=faBook;
  faHeadset=faHeadset;
  faCheck=faCheck;
  faUser=faUser;
  faCalendar=faCalendar;
  constructor() { }

  ngOnInit(): void {
  }

}
