<main>
  <div class="container" [hidden]="calendario.isLoading == true || atividades.isLoading == true">
    <h3 class="titulo-tela" style="margin-top: 1rem;">Agenda de Atividades</h3>
    <hr style="margin-bottom:2.5rem;">

    <agenda-carousel [currentSlide]="currentMonth" (selected)="setCalendarMonth($event)">
      <ng-container *ngFor="let item of items;">
        <ng-container *carouselItem>
          <div class="item">{{ item.title }}</div>
        </ng-container>
      </ng-container>
    </agenda-carousel>

    <agenda-calendario #calendario
      [profileId]="profileId"
      [currentMonth]="currentMonth"
      (selected)="selected = $event" (changeWeeks)="weeks = $event;" (weekSelected)="selectWeek($event)"></agenda-calendario>

    <agenda-carousel #semanas (selected)="setCalendarWeek($event)">
      <ng-container *ngFor="let item of calendario.weeks">
        <ng-container *carouselItem>
          <div class="item">{{ calendario.getNomeSemana(item) }}</div>
        </ng-container>
      </ng-container>
    </agenda-carousel>

    <agenda-atividades #atividades [profileId]="profileId" [weeks]="calendario.weeks" ></agenda-atividades>

  </div>


</main>
