import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from "@angular/core";
import { User } from '../model/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccessTokenService } from './accessToken.service';
import { environment } from 'src/environments/environment';
import { CrossStorageClient } from 'cross-storage';
import { AES, enc } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {
  userSubject = new BehaviorSubject<User | null>(null);
  allowedPlatforms = [
    'https://www.clubedolivrovirtual.com.br',
    'https://edufit.com.br',
    'https://www.leiturainterativa.com.br',
    'https://www.connectingenglish.com.br/',
    'https://www.caminhosdaaprendizagem.com.br'
  ];
  constructor(
    private http: HttpClient,
    private accessTokenService: AccessTokenService
  ) {
    if (this.isLogged()) {
      this.userSubject.next(this.accessTokenService.user);
    }
  }

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('grant_type', 'password');
    urlSearchParams.set('username', username);
    urlSearchParams.set('password', password);

    const body = urlSearchParams.toString();

    return this.http
                .post(environment.TOKEN_PATH, body, { headers })
                .pipe( tap( (data: any) => {
                  const user = { id: data.id, profileId: data.profile_id, username: data.username, email: data.email, role: data.role, version_active: data.version_active };
                  this.accessTokenService.token = data.access_token;
                  this.accessTokenService.expires = data.expires_in;
                  this.accessTokenService.user = user;
                  this.userSubject.next(user);
                  this.propagateToken(this.allowedPlatforms);
                }));
  }

  private propagateToken(linkGroup: string[]) {
    linkGroup.forEach( (link, index) => {
      var storage = new CrossStorageClient(link, {
        timeout: 30000,
        frameId: 'storageFrame_' + index
      });
      storage.onConnect().then( () => {
        storage.set('authUser', this.accessTokenService.authUser);
        storage.set('authToken', this.accessTokenService.token);
        storage.set('expiration', this.accessTokenService.expires);
      });
    });
  }

  private removeTokenAcrossPlatforms(linkGroup: string[]) {
    linkGroup.forEach( (link, index) => {
      var storage = new CrossStorageClient(link, {
        timeout: 30000,
        frameId: 'storageFrame_' + index
      });

      storage.onConnect().then( () => {
        storage.del('authToken','authUser', 'expiration');
      });
    });
  }

  logout(): void {
    this.userSubject.next(null);
    this.accessTokenService.clear();
    this.removeTokenAcrossPlatforms(this.allowedPlatforms);
    window.location.href = '/login';
  }

  getLoggedUser(): Observable<User | null> {
    return this.userSubject.asObservable();
  }

  isLogged(): boolean {
    return !!this.accessTokenService.token;
  }

  isExpired(): boolean {
    return ( new Date() > new Date(this.accessTokenService.expires) );
  }

  reset(email: string, password: string): Observable<any> {
    const token = this.accessTokenService.token;
    return this.http.post(environment.API_PATH + 'Account/Reset', {email, token, password});
  }

  signInFromToken(token: string): boolean | Observable<boolean> | Promise<boolean> {
    this.userSubject.next(null);
    this.accessTokenService.clear();
    this.removeTokenAcrossPlatforms(this.allowedPlatforms);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set('grant_type', 'password');
    urlSearchParams.set('username', token);
    urlSearchParams.set('password', token);

    const body = urlSearchParams.toString();

    return this.http.post(environment.TOKEN_PATH, body, {headers})
      .pipe(
        switchMap((data: any) => {
          const user = {id: data.id, profileId: data.profile_id, username: data.username, email: data.email, role: data.role, version_active: data.version_active};
          this.accessTokenService.token = data.access_token;
          this.accessTokenService.expires = data.expires_in;
          this.accessTokenService.user = user;
          this.userSubject.next(user);
          return of(true);
        })
      )
  }


}

