import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtml implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(v: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(v);
  }
}
