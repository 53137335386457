<div class="container">
    <div class="row mt-4">
        <div class="col-md-6">
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Pesquisar: nome, email, turma" aria-label="Pesquisar"
                    aria-describedby="button-addon2" [(ngModel)]="filtro">
                <button class="btn btn-outline-secondary"  type="button" id="button-addon2"><fa-icon [icon]="faSearch"></fa-icon></button>
            </div>
        </div>
    </div>
   
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Aluno</th>
                <th scope="col">Turma</th>
                <th scope="col">E-mail</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let dado of filtraAlunos()">
                <td id="id">{{ dado.id }}</td>
                <td id="nome">{{ dado.nome }}</td>
                <td id="turma">{{ dado.turma }}</td>
                <td id="turma">{{ dado.email }}</td>
                <td>
                    <button type="button" [disabled]="dado.turma" class="btn btn-success enturmacao-botao-enturmar mb-2"
                        data-bs-toggle="modal" data-bs-target="#enturmarModal" (click)="pegaId(dado)">Enturmar</button>
                    <button type="button" [disabled]="!dado.turma" class="btn btn-danger enturmacao-botao-desenturmar"
                        (click)="desenturmaAluno(dado)">Desenturmar</button>
                </td>
            </tr>
        </tbody>
    </table>


    <!-- MODAL ENTURMAR -->
    <div class="modal fade" id="enturmarModal" tabindex="-1" aria-labelledby="enturmarModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="enturmarModalLabel">Turma do Aluno</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <select class="form-select form-control" aria-label="Default select example" [(ngModel)]="turma"
                        (ngModelChange)="selecionaTurma()">
                        <option *ngFor="let turma of turmas" [value]="turma">{{turma}}</option>
                    </select>                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        (click)="enturmarAluno()">Enturmar</button>
                </div>
            </div>
        </div>
    </div>
</div>