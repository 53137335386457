import { AfterViewInit, Component, ContentChildren, ElementRef, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, EventEmitter, SimpleChanges } from '@angular/core';
import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import { AgendaCarouselItemDirective } from './agenda-carousel-item.directive';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'agenda-carousel',
  exportAs: 'carousel',
  template: `
    <div class="carousel">
      <button (click)="prev()" class="btn btn-default" *ngIf="showControls">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>

      <section class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">

        <ul class="carousel-inner" #carousel>
          <li *ngFor="let item of items" >
            <div #carouselItem>

              <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
            </div>
          </li>
        </ul>

      </section>
      <button (click)="next()" class="btn btn-default" *ngIf="showControls">
        <fa-icon [icon]="faAngleRight"></fa-icon>
      </button>
    </div>

  `,
    styles: [`
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 20000px;
    }

    .carousel {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .carousel-wrapper {
      overflow: hidden;
      background-color: #096043;
      border-radius: 0.938rem;
      height: 5rem;
    }

    .carousel-inner {
      display: flex;
    }

    button.btn {
      font-size: 3.5rem;
    }


  `]
})
export class AgendaCarouselComponent implements AfterViewInit {
  _defaultValue: number = 0;

  @ContentChildren(AgendaCarouselItemDirective) items!: QueryList<AgendaCarouselItemDirective>;
  @ViewChildren('carouselItem') private itemsElements!: QueryList<ElementRef>;
  @ViewChild('carousel') private carousel!: ElementRef;
  @Input() timing = '250ms ease-in';
  @Input() showControls = true;
  @Input() currentSlide = 0;
  @Output() selected = new EventEmitter<number>();

  private player!: AnimationPlayer;
  private itemWidth!: number;
  carouselWrapperStyle = {};

  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;

  constructor( private builder : AnimationBuilder ) {
  }

  setCurrentSlide() {
    if ( this.currentSlide > this.items.length ) return;

    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.buildAnimation(offset);

    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
    this.selected.emit(this.currentSlide);
  }

  next() {
    if( this.currentSlide + 1 === this.items.length ) return;
    this.currentSlide = (this.currentSlide + 1) % this.items.length;
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation : AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
    this.selected.emit(this.currentSlide);
  }

  prev() {
    if( this.currentSlide === 0 ) return;

    this.currentSlide = ((this.currentSlide - 1) + this.items.length) % this.items.length;

    const offset = this.setCurrentSlideOffset();

    const myAnimation : AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
    this.selected.emit(this.currentSlide);
  }

  ngAfterViewInit() {
    this.changeItemWidth();
    this.setCurrentSlide();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.changeItemWidth();
  }

  ngOnChanges(changes: SimpleChanges) {}

  private setCurrentSlideOffset() {
    const offset = this.currentSlide * this.itemWidth;
    return offset;
  }

  private buildAnimation( offset:number ) {
    return this.builder.build([
      animate(this.timing, style({ transform: `translateX(-${offset}px)` }))
    ]);
  }

  changeItemWidth() {
    this.itemWidth = window.innerWidth - (window.innerWidth * 0.40);
    this.itemsElements.forEach((item) => {
      item.nativeElement.style.width = `${this.itemWidth}px`;
    });
    this.carouselWrapperStyle = {
      width: `${this.itemWidth}px`
    };
    this.setCurrentSlide();
  }
}
