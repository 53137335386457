<main *ngIf="isLoading === false">
  <hr>
  <div class="container">
    <h3 class="titulo-filtro">
      Filtre sua busca
    </h3>
    <section class="d-flex flex-wrap filtro-busca">

      <div class="d-flex mb-3 mt-3" style="margin-left: 1rem;">
        <p class="tag-select mr-2">Plataforma:</p>
        <select (change)="filter()"
                [(ngModel)]="platformId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:300px;">
          <option value="0" class="text-select">Todas</option>
          <option [value]="p.id" *ngFor="let p of platformSelect" class="text-select">{{p.name}}</option>
        </select>
      </div>
    </section>
  </div>
  <hr>
  <div>
    <div class="container scroll-table" *ngFor="let p of periods">
      <div class="row text-center flex-nowrap">
        <table class="table table-striped table-hover mt-5">
          <thead>
            <tr>
              <th scope="col">{{ p.name }}</th>
              <th scope="col" *ngFor="let s of p.schedules">{{ s.name.replace("Semana", "") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of categories">
              <td>{{ c.name }}</td>
              <td *ngFor="let s of p.schedules">
                <fa-icon
                *ngIf="platformId == 0"
                [icon]="getObjTarefa(c.id, s).icon"
                [class]="getObjTarefa(c.id, s).class"
                [title]="getObjTarefa(c.id, s).title">
              </fa-icon>
              <span *ngIf="platformId == 57 || platformId == 15">
                {{ getObjGrade(c.id, s) }}
              </span>
              <span *ngIf="platformId == 7 || platformId == 59 || platformId == 62">
                {{ getObjDone(c.id, s) }}
              </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>
