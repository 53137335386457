import { SchoolPackageActivity } from './../model/schoolPackage.activity.model';
import { environment } from 'src/environments/environment';
import { Activity } from './../model/activity.model';
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { empty, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  constructor(private http: HttpClient) {}

  findPagedPerProfileIdAndCategoryId(filter: any) : Observable<Activity[]> {
    return this.http
               .post<Activity[]>(environment.API_PATH + 'hub/activity/perprofileandcategory', filter);
  }

  findPerProfileId(profileId: number): Observable<Activity[]> {
    if(!profileId) {
      return empty();
    }
    return this.http
               .get<Activity[]>(environment.API_PATH + 'hub/activity/perprofile/' + profileId);
  }

  findWithActivitiesPerProfileId(profileId: number): Observable<Activity[]> {
    if(!profileId) {
      return empty();
    }
    return this.http
               .get<Activity[]>(environment.API_PATH + 'hub/activity/perprofile/withcontents/' + profileId);
  }

  findPerSchoolPackageId(schoolPackageId: number): Observable<SchoolPackageActivity> {
    if(!schoolPackageId) {
      return empty();
    }

    return this.http
               .get<SchoolPackageActivity>(environment.API_PATH + 'hub/activity/perschoolpackage/' + schoolPackageId);
  }

  findPerMonth(profileId: number): Observable<Activity[]> {
    if(!profileId) {
      return empty();
    }
    return this.http
               .get<Activity[]>(environment.API_PATH + 'hub/activity/permonth/' + profileId);
  }


  findById(id: number): Observable<Activity> {
    return this.http
               .get<Activity>(environment.API_PATH + 'hub/activity/find/' + id );
  }

  findByIdAndProfileId(id: number, profileId: number): Observable<Activity> {
    return this.http
               .get<Activity>(environment.API_PATH + 'hub/activity/find/' + id + '/' + profileId );
  }

  findByCategoryId(id: number): Observable<Activity[]> {
    return this.http
               .get<Activity[]>(environment.API_PATH + 'hub/activity/percategory/' + id);
  }

  findOpenById(id: number): Observable<Activity> {
    return this.http
               .get<Activity>(environment.API_PATH + 'hub/activity/open/find/' + id);
  }

  findOpenBySlug(slug: string): Observable<Activity> {
    return this.http
               .get<Activity>(environment.API_PATH + 'hub/activity/open/find/slug/' + slug);
  }
}
