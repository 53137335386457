import { Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { AutenticacaoService } from "src/app/login/service/autenticacao.service";
import { ActivityService } from "../service/activity.service";
import { ActivatedRoute } from "@angular/router";
import { Activity } from "../model/activity.model";
import { Content } from "../model/content.model";


@Component({
  selector: 'app-atividade-somente-leitura',
  templateUrl: './atividade-somente-leitura.component.html',
  styleUrls: ['../atividade-secundaria/atividade-secundaria.component.css']
})
export class AtividadeSomenteLeituraComponent implements OnInit {
  isLoading: boolean = false;
  activity!: Activity;
  contents: Content[] = [];

  constructor(
    private activityService: ActivityService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.route.params
      .pipe(
        switchMap( (params) => this.activityService.findOpenBySlug(params?.slug)
      )
    ).subscribe( (activity) => {
      this.activity = activity;
      this.contents = activity.contents;
      this.isLoading = false;
    });
  }


}
