import { SanitizeHtml } from './pipes/sanitizeHtml.pipe';
import { NgModule } from "@angular/core";


@NgModule({
  declarations: [
    SanitizeHtml
  ],
  imports: [
  ],
  exports: [
    SanitizeHtml
  ]
})
export class SharedModule {

}
