import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faClipboard, faClipboardCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Activity } from 'src/app/atividades/model/activity.model';
import { ActivityService } from './../../atividades/service/activity.service';
import * as moment from 'moment';

@Component({
  selector: 'agenda-atividades',
  templateUrl: './agenda-atividades.component.html',
  styleUrls: ['./agenda-atividades.component.css']
})
export class AgendaAtividadesComponent implements OnChanges {
  isLoading: boolean = false;
  activities: Activity[] = [];
  weekActivities: Activity[] = [];
  currentWeek: number = 0;
  faClipboardCheck = faClipboardCheck;
  faExclamationTriangle = faExclamationTriangle;
  faClipboard = faClipboard;

  @Input() weeks: any[] = [];
  @Input() profileId: number = 0;

  constructor(
    private activityService: ActivityService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.weeks && JSON.stringify(changes.weeks.currentValue) !== JSON.stringify(changes.weeks.previousValue)) {
      this.setWeekActivities();
    }
    if (changes.profileId && changes.profileId.currentValue !== changes.profileId.previousValue) {
      this.isLoading = true;
      this.activityService
          .findPerProfileId(changes.profileId.currentValue)
          .subscribe( (result) => {
            this.activities = result;
            this.setWeekActivities();
            this.isLoading = false;
          });
    }
  }

  setWeekActivities() {
    this.weekActivities = ( this.activities.filter( (a) => {
      const activityDate = new Date(a.dueDate);

      const weekStart =  this.weeks[this.currentWeek][0];
      const weekEnd = this.weeks[this.currentWeek].at(-1);

      return (activityDate >= weekStart && activityDate <= weekEnd);
    }) );
  }


  isCurrentWeek(date: any): boolean {

    const weekStart =  this.weeks[this.currentWeek][0];
    const weekEnd = this.weeks[this.currentWeek][this.weeks.length - 1];

    return (new Date(date) >= weekStart && new Date(date) <= weekEnd);
  }

  isPastWeek(date: any): boolean {
    const weekStart =  this.weeks[this.currentWeek][0];

    return new Date(date) <= weekStart;
  }

  isNextWeek(date: any): boolean {
    const weekEnd = this.weeks[this.currentWeek][this.weeks.length - 1];

    return new Date(date) > weekEnd;
  }


}
