import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DesempenhoService {

  constructor() { }

  getMaterias(){
    return [
      {
        id: 1,
        nome: "LPL"
      },
      {
        id: 2,
        nome: "LPT"
      },
      {
        id: 3,
        nome: "LIT"
      },
      {
        id: 4,
        nome: "ING"
      },
      {
        id: 5,
        nome: "MAT"
      },
      {
        id: 6,
        nome: "FIS"
      },
      {
        id: 7,
        nome: "QUI"
      },
      {
        id: 8,
        nome: "BIO"
      },
      {
        id: 9,
        nome: "GEO"
      },
      {
        id: 10,
        nome: "HIS"
      },
      {
        id: 11,
        nome: "FIL"
      },
      {
        id: 12,
        nome: "SOC"
      },
    ]
  }
}
