<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card" *ngIf="contents.length > 0">
        <div class="card-body">
          <h6 class="card-title">

          </h6>
          <div class="content">
            <ul class="timeline">
              <li class="event" *ngFor="let c of contents;let i = index" [attr.data-date]="c.contentType.label">
                <div class="card card-timeline mb-3" *ngIf="c.video === false" style="cursor: pointer;">
                  <div class="row g-0">
                    <div class="col-md-4 card-timeline--imagem">
                      <img [src]="c.image" class="img-fluid rounded-start card-timeline--imagem--estilo" alt="...">
                    </div>
                  </div>
                </div>

                <div class="card card-timeline" *ngIf="c.video === true ">
                  <div class="card-body">
                    <h5 class="card-title card-timeline--title">{{activity.title}} ({{c.contentType.name}})</h5>
                    <p class="card-text">
                      <small class="text-muted card-timeline--small" *ngIf="c.description.length < 20">
                        Assista ao vídeo abaixo:
                      </small>

                      <small class="text-muted card-timeline--small" [innerHTML]="c.description"
                        *ngIf="c.description.length >= 20">
                      </small>
                    </p>
                  </div>
                  <iframe width="auto" height="500" [src]="c.link | sanitizeHtml" frameborder="0"
                    allowfullscreen></iframe>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
