import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { empty, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Period } from '../model/period.model';
import { SchoolPackagePeriod } from '../model/schoolPackage.period.model';

@Injectable({
  providedIn: 'root'
})
export class PeriodService {
  constructor(private http: HttpClient) {}

  findPerSchoolPackageId(schoolPackageId: number): Observable<SchoolPackagePeriod> {
    if(!schoolPackageId) {
      return empty();
    }

    return this.http
               .get<SchoolPackagePeriod>(environment.API_PATH + 'hub/period/perschoolpackage/' + schoolPackageId);
  }

  findPerSchoolPackageAndPeriod(schoolPackageId: number, periodId: number): Observable<SchoolPackagePeriod> {
    if(!schoolPackageId || !periodId) {
      return empty();
    }

    return this.http
               .get<SchoolPackagePeriod>(environment.API_PATH + 'hub/period/perschoolpackageperiod/' + schoolPackageId + '/' + periodId);
  }

  findWithActivitiesPerProfileId(profileId: number): Observable<Period[]> {
    if(!profileId) {
      return empty();
    }
    return this.http
               .get<Period[]>(environment.API_PATH + 'hub/period/perprofile/withcontents/' + profileId);
  }

  findWithActivitiesPerProfileIdAndPlatform(profileId: number, platformId: number): Observable<Period[]> {
    if(!profileId || !platformId) {
      return empty();
    }
    return this.http
               .get<Period[]>(environment.API_PATH + 'hub/period/perprofileandplatform/withcontents/' + profileId + '/' + platformId);
  }

  findAll(): Observable<Period[]> {
    return this.http
      .get<Period[]>(environment.API_PATH + 'hub/period/list');
  }

  findPerPackage(packageId: number): Observable<Period[]> {
    return this.http
      .get<Period[]>(environment.API_PATH + 'hub/period/perPackage/' + packageId);
  }

  findPerPackageAndPeriod(packageId: number, periodId: number): Observable<Period[]> {
    return this.http
      .get<Period[]>(environment.API_PATH + 'hub/period/PerPackageAndPeriod/' + packageId + '/' + periodId);
  }

}
