<main>
  <div class="container">
    <h3 class="titulo-filtro">Filtre sua busca</h3>

    <section class="d-flex flex-wrap filtro-busca">

      <div class="d-flex mb-3 mt-3">
        <p class="tag-select mr-2">Escola:</p>
        <select (change)="selectSchool()" [(ngModel)]="schoolId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:500px;">
          <option value="0" class="text-select">Todas</option>
          <option [value]="s.id" *ngFor="let s of schools" class="text-select">{{s.name}}</option>
        </select>
      </div>

      <div class="d-flex mb-3 mt-3">
        <p class="tag-select mr-2">Turma:</p>
        <select (change)="selectSchoolPackage()" [(ngModel)]="schoolPackageId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:500px;">
          <option value="0" class="text-select">Todas</option>
          <option [value]="sp.id" *ngFor="let sp of filteredSchoolPackages" class="text-select">{{sp.name}}</option>
        </select>
      </div>

    </section>
  </div>
  <hr>
  <div *ngIf="schoolPackageId > 0 && isLoading === false">
    <div class="container scroll-table" *ngFor="let p of periods">
      <div class="row text-center flex-nowrap">
        <table class="table table-striped table-hover mt-5">
          <thead>
            <tr>
              <th scope="col">{{ p.name }}</th>
              <th scope="col" *ngFor="let s of p.schedules">{{ s.name.replace("Semana", "") }}</th>
              <th scope="col">MEDIA</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of filteredCategories">
              <td>{{ c.name }}</td>
              <td *ngFor="let s of p.schedules">
                <span *ngIf="getObjTarefa(c.id, s) === true; else error;">
                  {{ groupedTasks[c.id][s.id].toFixed(2) }} %
                </span>
                <ng-template #error>
                  <fa-icon [icon]="faExclamationTriangle" class="atividade-padrao" title="Não foram encontrados dados..."></fa-icon>
                </ng-template>
              </td>
              <td>
                {{ getMediaBimestre(c.id, p.seq, p.schedules).toFixed(2)}}
              </td>
            </tr>
            <tr>
              <td></td>
              <td *ngFor="let s of p.schedules"></td>
              <td>
                {{ getMediaTotal(p.seq).toFixed(2) }} %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>
