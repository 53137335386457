<div class="container-fluid box-principal">
    <div class="row box-login col-md-4 mx-auto" (keyup.enter)="fazerLogin()" >

            <p class="titulo-login">Acesse sua conta</p>

            <input class="input mb-2" type="email" placeholder="Informe seu e-mail" [(ngModel)]="login.email">
            <input class="input mb-2" id="password" type="password" placeholder="Informe sua senha" [(ngModel)]="
                    login.senha">
                    <div class="revelar-senha">
                        <input class="revelar-senha-checkbox" type="checkbox" (click)="revelarsenha()"><span class="revelar-senha-texto">Mostrar senha</span>
                    </div>


            <div class="d-grid gap-2 col-6 mx-auto div-btn-logar mt-3">
                <button class="btn btn-logar" type="submit" (click)="fazerLogin()">Entrar</button>
            </div>


            <div class="container" style="
              display: flex;
              align-items: flex-end;
              justify-content: center;
            ">
              <div class="row box-esqueci-senha">
                  <p class="texto-recupera-senha">Versão {{ version }}</p>
              </div>
            </div>

    </div>

</div>
