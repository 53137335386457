<div class="calendario-container">
  <ul class="days-container">
    <li class="days" *ngFor="let day of days; let i = index;" [class.last]="i === days.length - 1">
      {{ day }}
    </li>
    <li *ngFor="let date of dates"
        [ngClass]="{gray: !isSameMonth(date)}"
        (click)="selected.emit(date)">
      {{ date | date: 'd'}}
    </li>
  </ul>
  <ul class="week">
    <li style="border: none;"></li>
    <li *ngFor="let w of weeks; let i = index;" (click)="selectWeek(i)">
      <span *ngIf="!isLoading">
        {{ getNomeSemana(w) }}
      </span>

    </li>
  </ul>
</div>

