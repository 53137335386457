import { RecordModel } from './model/record.model';
import { RecordFilter } from './model/record.filter.model';
import { ProfileSchoolPackageService } from './../shared/service/profileSchoolPackage.service';
import { RecordService } from './service/record.service';
import { AutenticacaoService } from './../login/service/autenticacao.service';
import { Component } from "@angular/core";
import { finalize, switchMap } from 'rxjs/operators';
import { SchoolPackage } from '../shared/model/schoolPackage.model';
import { Router } from '@angular/router';
import * as _ from 'underscore';

@Component({
  selector: 'app-professor',
  templateUrl: './professor.component.html',
  styleUrls: ['./professor.component.css']
})
export class ProfessorComponent {
  recordFilter!: RecordFilter;
  schoolPackages: any[] = [];
  schoolPackageId: number = 0;
  platformSelect: any[] = [
    {id: 57, name: 'EduFit'},
    {id: 62, name: 'EduQuest'},
    {id: 7, name: 'Clube do Livro'},
    {id: 59, name: 'Leitura Interativa'},
    {id: 15, name: 'Connecting English'}
  ];
  platformId: number = 0;
  records: RecordModel[] = [];
  searchName: string = "";
  isLoading = true;

  constructor(
    private router: Router,
    private recordService: RecordService,
    private autenticacaoService: AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.autenticacaoService
        .getLoggedUser()
        .pipe(
          switchMap( (u) => {
            if (u?.role === 'Students') {
              this.router.navigate(['/mural']);
            }
            return this.profileSchoolPackageService.findPerProfileId(u?.profileId as number)
          }),
          switchMap( (psp) => {
            this.schoolPackages = psp.filter(x => x.schoolPackage.name !== 'Galera Cult').map(x => ({ id: x.schoolsPackagesId, name: x.schoolPackage.name }));
            this.schoolPackages = _.sortBy(this.schoolPackages, (x) => parseInt(x.name) || 0);

            this.recordFilter =
            {
              name: "",
              page: 1,
              platformId: 0,
              schoolsPackagesIds: psp.filter(x => x.schoolPackage.name !== 'Galera Cult').map(x => x.schoolsPackagesId),
              year: new Date().getFullYear()
            };
            return this.recordService.findFiltered(this.recordFilter)
          })
        )
        .subscribe( (r) => {
          this.isLoading = false;
          this.records = r;
        });
  }

  filter(): void {
    this.isLoading = true;
    this.recordFilter.page = 1;
    this.recordFilter.platformId = this.platformId;
    this.recordFilter.name = this.searchName;
    this.recordFilter.schoolsPackagesIds = this.schoolPackages.filter(x => this.schoolPackageId == 0 || x.id == this.schoolPackageId).map(x => x.id)
    this.recordService
        .findFiltered(this.recordFilter)
        .pipe( finalize( () => this.isLoading = false))
        .subscribe( (r) => {
          this.records = r;
        });
  }


  nextPage(): void {
    this.recordFilter.page++;
    this.recordService
        .findFiltered(this.recordFilter)
        .subscribe( (r) => {
          this.records = this.records.concat(r);
        });
  }

}
