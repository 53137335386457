import { AutenticacaoService } from './../login/service/autenticacao.service';
import { ActivityService } from './../atividades/service/activity.service';
import { Observable, forkJoin, of } from 'rxjs';
import { MuralService } from './mural.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Mural } from './Mural';
import { User } from '../login/model/user.model';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { Activity } from '../atividades/model/activity.model';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Dictionary } from 'underscore';
import { Package } from '../shared/model/package.model';
import { Category } from '../shared/model/category.model';
import { ProfileSchoolPackageService } from '../shared/service/profileSchoolPackage.service';
import { SchoolPackage } from '../shared/model/schoolPackage.model';
import { CategoryService } from '../shared/service/category.service';
import { AccessTokenService } from '../login/service/accessToken.service';

@Component({
  selector: 'app-mural',
  templateUrl: './mural.component.html',
  styleUrls: ['./mural.component.css']
})
export class MuralComponent implements OnInit {
  currentDate = moment();

  itemsSelect: string[] = ['Todos', 'Hoje', 'Esta Semana', 'Este Mês'];
  selected: string = 'Todos';
  schoolPackageId: number = 0;
  categoryId: number = 0;
  atividadesMural!: any[];
  usuario: User | null = null;
  atividades: Activity[] = [];
  atividadesFiltradas: Activity[] = [];
  isLoading = true;
  loadingMoreActivities = false;
  page: number = 1;
  groupedPackages: SchoolPackage[] = [];
  groupedCategories: Category[] = [];
  search: string = "";

  constructor(
    private muralService: MuralService,
    private activityService: ActivityService,
    private profileSchoolPackageService: ProfileSchoolPackageService,
    private categoryService: CategoryService,
    private autenticacaoService: AutenticacaoService,
    private accessTokenService: AccessTokenService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.atividadesMural = this.muralService.getAtividadesMural();
    this.autenticacaoService
        .getLoggedUser()
        .pipe(
          switchMap( (u) => {
            this.usuario = u;

            if(u?.version_active === "new") {
              this.accessTokenService.createToken(this.usuario?.email as string).subscribe( (token) => {
                const link = `https://novoiclassroom.azurewebsites.net/mural?FromUser=${token}`;
                window.open(link, '_self');
              });
            }

            return this.profileSchoolPackageService.findPerProfileId(u?.profileId as number);
          }),
          switchMap( (psp) => {
            this.groupedPackages = _.sortBy(
              _.filter(
                _.pluck(psp, 'schoolPackage'),(x) => x.name !== 'Galera Cult'
              ), (x) => parseInt(x.name)
            );

            this.schoolPackageId = (this.groupedPackages.length === 1) ? this.groupedPackages[0].id : 0;
            const packageId = (this.groupedPackages.length === 1) ? this.groupedPackages[0].packageId : 0;
            return this.categoryService.findPerPackageId(packageId);
          }),
          switchMap( (c) => {

            this.groupedCategories = _.sortBy(c, (x) => x.name);

            this.categoryId = 0;
            this.page = 1;
            return this.activityService.findPagedPerProfileIdAndCategoryId(
              {
                 profileId: this.usuario?.profileId as number,
                 categoryId: this.categoryId,
                 schoolPackageId: this.schoolPackageId,
                 page: this.page,
                 search: this.search
              })
          })
        )
        .subscribe( (a) => {
          this.atividades = this.atividadesFiltradas = a;
          this.isLoading = false;
        });
  }

  reloadActivitiesCategories(){
    this.isLoading = true;
    this.page = 1;
    this.activityService.findPagedPerProfileIdAndCategoryId(
      {
        profileId: this.usuario?.profileId as number,
        categoryId: this.categoryId,
        schoolPackageId: this.schoolPackageId,
        page: this.page,
        porMes: false,
        search: this.search
      })
      .pipe(finalize( () => this.isLoading = false))
      .subscribe( (a) => {
        this.atividades = this.atividadesFiltradas = a;
      });
  }

  reloadActivitiesSchoolPackage() {
    const packageId = (this.schoolPackageId == 0) ? 0 :
      this.groupedPackages.find( (x) => x.id == this.schoolPackageId)?.packageId as number;

    this.isLoading = true;
    this.page = 1;
    this.categoryId = 0;

    this.categoryService.findPerPackageId(packageId)
    .subscribe( (c) => {
      this.groupedCategories = _.sortBy(c, (x) => x.name);
      this.reloadActivitiesCategories();
    });

  }

  reloadActivitiesSearch() {
    this.isLoading = true;
    this.loadingMoreActivities = true;
    this.page = 1;
    this.activityService.findPagedPerProfileIdAndCategoryId(
      {
        profileId: this.usuario?.profileId as number,
        categoryId: this.categoryId,
        schoolPackageId: this.schoolPackageId,
        page: this.page,
        search: this.search,
        porMes: false
      })
      .pipe(finalize( () => {
        this.isLoading = false;
        this.loadingMoreActivities = false;
      }))
      .subscribe( (a) => {
        this.atividades = this.atividadesFiltradas = a;
      });
  }

  nextPage(){
    this.loadingMoreActivities = true;
    this.page++;
    this.activityService.findPagedPerProfileIdAndCategoryId(
      {
        profileId: this.usuario?.profileId as number,
        categoryId: this.categoryId,
        schoolPackageId: this.schoolPackageId,
        page: this.page,
        search: this.search,
        porMes: false
      })
      .pipe(finalize( () => this.loadingMoreActivities = false))
      .subscribe( (a) => {
        this.atividades = this.atividades.concat(a);
        this.atividadesFiltradas = this.atividadesFiltradas.concat(a);
      });
  }

  optVersionIn() {
    this.isLoading = true;
    this.profileSchoolPackageService
      .optVersionIn(this.usuario?.profileId as number)
      .subscribe( (_) => {
        this.accessTokenService.createToken(this.usuario?.email as string).subscribe( (token) => {
          const link = `https://novoiclassroom.azurewebsites.net/materias?FromUser=${token}`;
          window.open(link, '_self');
        });
      });
  }


}
