import { ProfileSchoolPackageService } from './../shared/service/profileSchoolPackage.service';
import { Component, OnInit } from "@angular/core";
import { AutenticacaoService } from "../login/service/autenticacao.service";
import { switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { EMPTY, forkJoin, of } from "rxjs";
import * as _ from 'underscore';
import { CategoryService } from '../shared/service/category.service';
import { PeriodService } from '../atividades/service/period.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileSchoolPackage } from '../shared/model/profileSchoolPackage.model';
import { RecordService } from '../professor/service/record.service';
import { Period } from '../atividades/model/period.model';
import { SchoolPackagePeriod } from '../atividades/model/schoolPackage.period.model';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css']
})
export class PerformanceComponent implements OnInit {
  isLoading: boolean = false;
  firstLoad: boolean = true;

  schoolPackages: any[] = [];
  schoolPackageId: number = 0;

  schoolPackagePeriod: Period[] = [];
  periods: Period[] = [];
  periodId: number = 0;

  platformSelect: any[] = [
    {id: 57, name: 'EduFit'},
    {id: 15, name: 'Connecting English'}
  ];
  platformId: number = 0;

  filteredCategories: any[] = [];
  categories: any[] = [];
  categoryId: number = 0;

  profileSchoolPackages: ProfileSchoolPackage[] = [];

  records: any[] = [];
  filteredRecords: any[] = [];

  gradeTotal: number = 0;
  weekNumbers: {id: number, periodId: number, weekNumber:number} [] = [];

  scheduleId: number = 0;
  filteredSchedules: any[] = [];
  gradesMap: Map<string, string> = new Map<string, string>();

  page: number = 1;

  loadingNextPage: boolean = false;

  constructor(
    private autenticacaoService: AutenticacaoService,
    private router: Router,
    private profileSchoolPackageService: ProfileSchoolPackageService,
    private categoryService: CategoryService,
    private periodService: PeriodService,
    private toastrService: ToastrService,
    private recordService: RecordService
  ){}

  ngOnInit(): void {
    this.isLoading = true;
    this.autenticacaoService
        .getLoggedUser()
        .pipe(
          switchMap( (u) => {
            if(u?.role === 'Students') {
              this.router.navigate(['/mural']);
            }

            return  this.profileSchoolPackageService.findPerProfileId(u?.profileId as number);
          })
        )
        .subscribe( (result) => {
          this.schoolPackages = result
            .filter(x => x.schoolPackage.name !== 'Galera Cult')
            .map(x => ({id: x.schoolsPackagesId, name: x.schoolPackage.name, packageId: x.schoolPackage.packageId }));

          this.schoolPackages = _.sortBy(this.schoolPackages, (x) => parseInt(x.name) || 0);


          this.isLoading = false;
        });
  }

  filter(): void {
    if(this.periodId == 0 || this.schoolPackageId == 0) {
      return;
    }

    const packageId = this.schoolPackages.find(x => x.id == this.schoolPackageId)?.packageId;

    this.platformId = 0;
    this.categoryId = 0;
    this.filteredCategories = [];
    this.page = 1;
    this.isLoading = true;

    this.periodService
        .findPerPackageAndPeriod(packageId, this.periodId)
        .pipe(
          switchMap( (r) => {
            this.schoolPackagePeriod = r;

            return this.profileSchoolPackageService.findPagedPerPackage(this.schoolPackageId, 1);
          }),
          switchMap( (s) => {
            const schedules = (_.flatten(_.pluck(this.schoolPackagePeriod, 'schedules')));
            const activities = (_.uniq(_.flatten(_.pluck(schedules, 'activities'))));
            const referenceIds = (_.flatten(_.pluck(activities, 'contents')).filter( x => x.platformId == this.platformId).map(x => parseInt(x.link.replace(/\D/g,''))));

            const profileIds = _.pluck(s, 'profileId');

            this.profileSchoolPackages = s;


            return forkJoin(
                this.recordService.findFilteredPerStudent({
                profileIds: profileIds,
                referenceIds: referenceIds,
                schoolsPackagesIds: [this.schoolPackageId],
                platformId: this.platformId,
                year: new Date().getFullYear()
              }),
              this.categoryService.findPerPackageId(packageId)
            );

          })

        )
        .subscribe(result => {
          this.records = this.filteredRecords = result[0];

          this.categories = result[1];

          const packageExists = result[0].filter(x => x.schoolPackage.packageId < 10);

          if(packageExists.length > 0) {
            this.categories.push({ id: 9999, name: "Matemática 2", description: "mat2", shortName: "Mat 2", image: "", imageAlt: "" });
            this.categories = _.sortBy(this.categories, (x) => x.name);
          }

          this.filteredCategories = this.categories;


          this.filteredSchedules = this.weekNumbers.filter(x => x.periodId == this.periodId);
          this.gradesMap = new Map<string, string>();
          this.gradeTotal = 0;
          this.isLoading = false;
          this.firstLoad = false;
        });

  }

  resetFilter() {
      this.records = this.filteredRecords = [];
      this.profileSchoolPackages = [];
      this.categories = this.filteredCategories = [];
      this.gradesMap = new Map<string, string>();
      this.firstLoad = true;
      this.platformId = 0;
      this.categoryId = 0;
      this.periodId = 0;
      this.scheduleId = 0;
      this.page = 1;
      this.filterPeriods();
  }

  filterPeriods() {
    this.isLoading = true;

    const packageId = this.schoolPackages.find(x => x.id == this.schoolPackageId)?.packageId;

    this.periodService
        .findPerPackage(packageId)
        .subscribe( (result) => {
          this.periods = result;
          this.weekNumbers = (_.flatten(_.pluck(this.periods, 'schedules'))).map(x => ( {id: x.id, periodId: x.periodId, weekNumber: parseFloat(x.name.replace( /^\D+/g, '') ) } ));
          this.isLoading = false;
        });
  }

  getObjPerformance(profileId: number, categoryId: number, periodId: number, schedule: any): string {
    const key = `${profileId}_${categoryId}_${periodId}_${schedule.id}`;

    if(this.gradesMap.has(key)) {
      return this.gradesMap.get(key) || '-';
    }

    const schedules = (_.flatten(_.pluck(this.schoolPackagePeriod, 'schedules')));
    const activities = (_.flatten(_.pluck(schedules.filter(x => x.id == schedule.id), 'activities')));
    const contents = (_.flatten(_.pluck(activities, 'contents')));

    const filteredContents = contents.filter( x =>
      x.platformId == 57 || x.platformId == 15 || x.platformId == 62
    );

    const referenceIds = filteredContents.map(x => parseInt(x.link.replace(/^\D+/g, '')));

    let category_records = this.filteredRecords.filter( (x) => x.profileId == profileId && referenceIds.includes(x.referenceId));
    let filtered_records: any[] = [];
    let sum = 0;
    let avg = 0;

    if(categoryId == 6 || categoryId == 9999 ) {

        const numeracao = schedule.weekNumber;

        filtered_records = (categoryId == 6) ?
          category_records.filter( (x) => x.course.categoryId == 6 && parseFloat(x.referenceName.replace( /^\D+/g, '')) == numeracao) :
          category_records.filter( (x) => x.course.categoryId == 6 && parseFloat(x.referenceName.replace( /^\D+/g, '')) >  numeracao) ;
      } else if (categoryId === 12 || categoryId === 36) {

        filtered_records = category_records.filter( (x) => x.course.categoryId == 12 || x.course.categoryId == 36);

      } else {
        filtered_records = category_records.filter( (x) => x.course.categoryId == categoryId);
      }

      if(filtered_records.length == 0) {
        this.gradesMap.set(key, '-');
        return '-';
      }

      filtered_records.forEach(x => {
        sum += (x.gradeNumber > 10) ? 10 : x.gradeNumber;
      });
      avg = (sum / filtered_records.length);
      if(avg > 10) {
        this.gradesMap.set(key, '-');
        return '10.00';
      }
      this.gradesMap.set(key, avg.toFixed(2));
      return avg.toFixed(2);
  }

  getObjPerformanceAluno(profileId: number, periodId: number, s: any = null) {

    let sum = 0;
    let avg = 0;
    let deno = 0;

    s.forEach( (x:{ id: number, periodId: number, weekNumber:number }) => {
      let cat_sum = parseFloat(this.getObjPerformance(profileId, this.categoryId, periodId, x));
      if(!isNaN(cat_sum)) {

        sum += cat_sum;
        deno++;
      }
    });

    if(deno === 0) {
      return '-';
    }

    avg = sum / deno;

    if(avg > 10) {
      return '10.00';
    }
    return avg.toFixed(2);

  }

  getObjPerformanceCategory(profileId: number, categoryId: number) {

    let sum = 0;
    let avg = 0;
    let deno = 0;

    this.filteredSchedules.forEach( (x:{ id: number, periodId: number, weekNumber:number }) => {
      let cat_sum = parseFloat(this.getObjPerformance(profileId, categoryId, x.periodId, x));
      if(!isNaN(cat_sum)) {

        sum += cat_sum;
        deno++;
      }
    });

    if(deno === 0) {
      return '-';
    }

    avg = sum / deno;

    if(avg > 10) {
      return '10.00';
    }

    return avg.toFixed(2);

  }

  getObjPerformanceTotal(profileId: number) {

    let sum = 0;
    let avg = 0;
    let deno = 0;

    this.filteredCategories.forEach( x  => {
      let cat_sum = parseFloat(this.getObjPerformanceCategory(profileId, x.id));
      if(!isNaN(cat_sum)) {

        sum += cat_sum;
        deno++;
      }
    });

    if(deno === 0) {
      return '-';
    }

    avg = sum / deno;

    if(avg > 10) {
      return '10.00';
    }

    return avg.toFixed(2);
  }

  nextPage(): void {
    this.page++;
    this.loadingNextPage = true;
    this.profileSchoolPackageService.findPagedPerPackage(this.schoolPackageId, this.page)
        .pipe(
          switchMap( (s) => {
            const schedules = (_.flatten(_.pluck(this.schoolPackagePeriod, 'schedules')));
            const activities = (_.uniq(_.flatten(_.pluck(schedules, 'activities'))));
            const referenceIds = (_.flatten(_.pluck(activities, 'contents')).filter( x => x.platformId == this.platformId).map(x => parseInt(x.link.replace(/\D/g,''))));

            const profileIds = _.pluck(s, 'profileId');


            return forkJoin(this.recordService.findFilteredPerStudent({
              profileIds: profileIds,
              referenceIds: referenceIds,
              schoolsPackagesIds: [this.schoolPackageId],
              platformId: this.platformId,
              year: new Date().getFullYear()
            }), of(s));

          })

        )
        .subscribe(result => {
          this.records = this.records.concat(result[0]);
          this.filteredRecords = this.filteredRecords.concat(result[0]);
          this.profileSchoolPackages = this.profileSchoolPackages.concat(result[1]);
          this.gradesMap = new Map<string, string>();
          this.loadingNextPage = false;
        });
  }



}
