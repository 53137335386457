import { DashboardComponent } from './dashboard/dashboard.component';
import { AgendaAtividadesComponent } from './agenda/agenda-atividades/agenda-atividades.component';
import { AgendaCalendarioComponent } from './agenda/agenda-calendario/agenda-calendario.component';
import { AgendaCarouselItemDirective } from './agenda/agenda-carousel/agenda-carousel-item.directive';
import { AgendaCarouselComponent } from './agenda/agenda-carousel/agenda-carousel.component';

import { SharedModule } from './shared/shared.module';
import { AgendaComponent } from './agenda/agenda.component';
import { TurmaEsforcoComponent } from './turma-esforco/turma-esforco.component';
import { AlunoNotasComponent } from './aluno-notas/aluno-notas.component';
import { TurmaComponent } from './turma/turma.component';
import { AlunoIndividualComponent } from './aluno-individual/aluno-individual.component';
import { AlunoComponent } from './aluno/aluno.component';
import { ProfessorComponent } from './professor/professor.component';
import { PerfilModule } from './perfil/perfil.module';

import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MuralComponent } from './mural/mural.component';
import { DesempenhoComponent } from './desempenho/desempenho.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { LoginComponent } from './login/login.component';
import { EsqueceuSenhaComponent } from './esqueceu-senha/esqueceu-senha.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnturmacaoComponent } from './enturmacao/enturmacao.component';
import { CommonModule, registerLocaleData, DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { RequestInterceptor } from './request.interceptor';
import ptBr from '@angular/common/locales/pt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { PerformanceComponent } from './performance/performance.component';
import { AtividadeSomenteLeituraComponent } from './atividades/atividade-somente-leitura/atividade-somente-leitura.component';
registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    MuralComponent,
    DesempenhoComponent,
    LoginComponent,
    EsqueceuSenhaComponent,
    EnturmacaoComponent,
    AlunoComponent,
    AlunoIndividualComponent,
    AlunoNotasComponent,
    ProfessorComponent,
    TurmaComponent,
    TurmaEsforcoComponent,
    AgendaComponent,
    AgendaCarouselComponent,
    AgendaCarouselItemDirective,
    AgendaCalendarioComponent,
    AgendaAtividadesComponent,
    HomeComponent,
    DashboardComponent,
    PerformanceComponent,
    AtividadeSomenteLeituraComponent
  ],
  imports: [
    NgProgressModule.withConfig({
      spinnerPosition: "left",
      color: "#ddd"
    }),
    NgProgressHttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,
    GoogleChartsModule,
    PerfilModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    SharedModule,
    ToastrModule.forRoot(),
    NgbModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-PT'
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
