import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisciplinaService {

  constructor(private http: HttpClient) { }

  getDisciplinas() {
    return [
      {
        id: 1,
        nome: "Matemática",
        conteudo: {
          aula1: ["Aula 1.1", "Funcão Quadratica", "30/08/21"],
          aula2: ["Aula 1.2", "Trigonometria", "30/08/2021"],
          aula3: ["Aula 1.3", "Geometria Plana", "30/08/2021"]
        },
        extras: ["Atividade - Funcão de 1 grau", "Aula - Função de 1 grau", "Atividade - Teoria dos Conjuntos"],
        pendente: ["Teoria dos Conjuntos", "Geometria Plana", "Função de 1 grau"]
      },
      {
        id: 2,
        nome: "Biologia",       
        conteudo: {
          aula1: ["Aula 1.1", "Citologia", "30/08/21"],
          aula2: ["Aula 1.2", "Sistema Nervoso", "30/08/2021"],
          aula3: ["Aula 1.3", "Evolução", "30/08/2021"]
        },
        extras: ["Atividade - Citologia", "Aula - Introdução à Biologia", "Atividade - Reino Vegetal"],
        pendente: ["Reino Vegetal", "Sistema Nervoso", "Citologia"]
      },
      {
        id: 3,
        nome: "Física",        
        conteudo: {
          aula1: ["Aula 1.1", "Mecânica", "30/08/21"],
          aula2: ["Aula 1.2", "Hidrostática", "30/08/2021"],
          aula3: ["Aula 1.3", "Gravidade", "30/08/2021"]
        },
        extras: ["Atividade - Mecânica", "Aula - Grandezas Físicas", "Atividade - Termologia"],
        pendente: ["Termologia", "Mecânica", "Leis dos Gases"]
      },
      {
        id: 4,
        nome: "Inglês",       
        conteudo: {
          aula1: ["Aula 1.1", "Present Perfect", "30/08/21"],
          aula2: ["Aula 1.2", "Present Continuos", "30/08/2021"],
          aula3: ["Aula 1.3", "To be", "30/08/2021"]
        },
        extras: ["Atividade - Present Perfect", "Aula - Adverbs", "Atividade - To be"],
        pendente: ["To be", "Present Perferct", "Has e Have"]
      },
      {
        id: 5,
        nome: "Química",        
        conteudo: {
          aula1: ["Aula 1.1", "Química Orgânica", "30/08/21"],
          aula2: ["Aula 1.2", "Fusão Nuclear", "30/08/2021"],
          aula3: ["Aula 1.3", "Equilíbrio Químico", "30/08/2021"]
        },
        extras: ["Atividade - Química Orgânica", "Aula - Ligações Covalente", "Atividade - Fusão Nuclear"],
        pendente: ["Ácidos e Bases", "Fusão Nuclear", "Polímeros"]
      },
      {
        id: 6,
        nome: "Biologia",       
        conteudo: {
          aula1: ["Aula 1.1", "Leis de Mendel", "30/08/21"],
          aula2: ["Aula 1.2", "Origem da Vida", "30/08/2021"],
          aula3: ["Aula 1.3", "Biodiversidade", "30/08/2021"]
        },
        extras: ["Atividade - Leis de Mendel", "Aula - Citologia", "Atividade - Origem da Vida"],
        pendente: ["Origem da Vida", "Citologia", "Reino Vegetal"]
      },
      {
        id: 7,
        nome: "Geografia",       
        conteudo: {
          aula1: ["Aula 1.1", "Urbanização", "30/08/21"],
          aula2: ["Aula 1.2", "Globalização", "30/08/2021"],
          aula3: ["Aula 1.3", "Clima", "30/08/2021"]
        },
        extras: ["Atividade - Urbanização", "Aula - População", "Atividade - Clima"],
        pendente: ["Clima", "Climas do Brasil", "Neoliberalismo"]
      },
      {
        id: 8,
        nome: "História",        
        conteudo: {
          aula1: ["Aula 1.1", "História Medieval", "30/08/21"],
          aula2: ["Aula 1.2", "História da Arte", "30/08/2021"],
          aula3: ["Aula 1.3", "História Antiga", "30/08/2021"]
        },
        extras: ["Atividade - História Medieval", "Aula - História da Arte", "Atividade - História Antiga"],
        pendente: ["História Antiga", "Revolução Francesa", "História Medieval"]
      },
      {
        id: 9,
        nome: "Filosofia",        
        conteudo: {
          aula1: ["Aula 1.1", "Introdução à Filosofia", "30/08/21"],
          aula2: ["Aula 1.2", "Confúcio e Seus Ensinamentos", "30/08/2021"],
          aula3: ["Aula 1.3", "Os Sofistas", "30/08/2021"]
        },
        extras: ["Atividade - Introdução à Filosofia", "Aula - Os Sofista", "Atividade - Os Sofistas"],
        pendente: ["Os Sofistas", "Parmênides", "Meritocracia"]
      },
      {
        id: 10,
        nome: "Sociologia",       
        conteudo: {
          aula1: ["Aula 1.1", "Processos sociais", "30/08/21"],
          aula2: ["Aula 1.2", "A Sociedade", "30/08/2021"],
          aula3: ["Aula 1.3", "Grupos Sociais", "30/08/2021"]
        },
        extras: ["Atividade - Processos Socias", "Aula - Grupos Sociais", "Atividade - A Sociedade"],
        pendente: ["A Sociedade", "Socialismo", "Processos Sociais"]
      },
      {
        id: 11,
        nome: "Literatura",        
        conteudo: {
          aula1: ["Aula 1.1", "Literatura Brasileira", "30/08/21"],
          aula2: ["Aula 1.2", "Romantismo", "30/08/2021"],
          aula3: ["Aula 1.3", "Realismo", "30/08/2021"]
        },
        extras: ["Atividade - Romantismo", "Aula - Realismo", "Atividade - Literatura Brasileira"],
        pendente: ["Romantismo", "Contemporaneidade", "Literatura Brasileira"]
      }


    ]


  }

  getDisciplina(id: number) {
    return this.getDisciplinas().filter(x => x.id == id);
  }


}
