import { Schedule } from './../atividades/model/schedule.model';
import { PeriodService } from './../atividades/service/period.service';
import { RecordService } from './../professor/service/record.service';
import { switchMap, finalize } from 'rxjs/operators';
import { ActivityService } from './../atividades/service/activity.service';
import { CategoryService } from './../shared/service/category.service';
import { ProfileSchoolPackageService } from './../shared/service/profileSchoolPackage.service';
import { AutenticacaoService } from './../login/service/autenticacao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from "@angular/core";
import { Activity } from "../atividades/model/activity.model";
import { Category } from "../shared/model/category.model";
import { ProfileSchoolPackage } from "../shared/model/profileSchoolPackage.model";
import { forkJoin } from 'rxjs';
import { faClipboardCheck, faClipboard, faExclamationTriangle, faClipboardList, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import * as moment from 'moment';
import * as _ from 'underscore';
import { User } from '../login/model/user.model';
import { RecordModel } from '../professor/model/record.model';
import { Period } from '../atividades/model/period.model';

@Component({
  selector: 'app-aluno-notas',
  templateUrl: './aluno-notas.component.html',
  styleUrls: ['./aluno-notas.component.css']
})
export class AlunoNotasComponent {
  user!: User | null;
  studentInfo!: ProfileSchoolPackage;
  groupedActivities!: Activity[][];
  periods: Period[] = [];
  filteredRecords!: RecordModel[];
  categories!: Category[];
  isLoading = true;

  faClipboardCheck = faClipboardCheck;
  faClipboard = faClipboard;
  faExclamationTriangle = faExclamationTriangle;
  faClipboardList = faClipboardList;
  faMinusCircle = faMinusCircle;

  currentWeek: number = 0;

  platformSelect: any[] = [
    { id: 57, name: 'EduFit' },
    { id: 62, name: 'EduQuest' },
    { id: 7, name: 'Clube do Livro' },
    { id: 59, name: 'Leitura Interativa' },
    { id: 15, name: 'Connecting English' }
  ];
  platformId: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private autenticacaoService: AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService,
    private recordService: RecordService,
    private categoryService: CategoryService,
    private activityService: ActivityService,
    private periodService: PeriodService
  ) { }


  ngOnInit(): void {
    this.isLoading = true;
    this.autenticacaoService
      .getLoggedUser()
      .pipe(
        switchMap((u) => {
          this.user = u;
          const studentId = u?.profileId as number;
          const infoAluno$ = this.profileSchoolPackageService.findPerProfileId(studentId);
          const infoAtividades$ = this.periodService.findWithActivitiesPerProfileId(studentId);
          const infoCategoriesHub$ = this.categoryService.findAll('Hub');
          const infoCategoriesCH$ = this.categoryService.findAll('CH');

          return forkJoin([infoAluno$, infoAtividades$, infoCategoriesHub$, infoCategoriesCH$]);
        })
      )
      .subscribe((result) => {
        this.studentInfo = result[0].filter(x => x.schoolPackage.name !== 'Galera Cult')[0];
        this.periods = result[1];
        const schedules = (_.flatten(_.pluck(result[1], 'schedules')));
        const activities = (_.uniq(_.flatten(_.pluck(schedules, 'activities'))));

        this.categories = result[2].filter(x => activities.filter(y => y.categoryId === x.id).length > 0);

        if (this.studentInfo.schoolPackage.package.id < 10) {
          this.categories.push({ id: 9999, name: "Matemática 2", description: "mat2", shortName: "Mat 2", image: "", imageAlt: "" });
        }

        this.categories = _.sortBy(this.categories, (x) => x.name);
        this.categories = this.categories.concat(result[3].filter(x => activities.filter(y => y.categoryId === x.id).length > 0));

        this.currentWeek = (moment(new Date()).week());

        this.isLoading = false;
      });
  }

  getObjTarefa(categoryId: number, schedule: Schedule): { icon: any, class: string, title: string } {
    let cat = (categoryId === 9999) ?
      schedule.activities?.filter(x => x.categoryId === 6) :
      schedule.activities?.filter(x => x.categoryId === categoryId);

    if (cat && this.studentInfo.schoolPackage.packageId < 10 && (categoryId === 6 || categoryId === 9999)) {
      const numeracoes = _.uniq(cat.map((x) => parseInt(x.title.replace(/^\D+/g, '')))).sort((a, b) => a - b);
      if (categoryId === 6) {
        cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[0]);
      } else {
        cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[numeracoes.length - 1]);
      }
    }

    if (!cat || cat.length === 0) {
      return (schedule.weekNumber <= this.currentWeek) ?
        { icon: faMinusCircle, class: "atividade-feito", title: "A fazer" } :
        { icon: faClipboard, class: "atividade-andamento", title: "A fazer" };
    }

    if (cat.filter(x => x.performed.length === 0 && this.isPastWeek(x.dueDate)).length > 0) {
      return { icon: faExclamationTriangle, class: "atividade-pendente", title: "Pendente" };
    }
    if (cat.filter(x => x.performed.length === 0 && (this.isCurrentWeek(x.dueDate) || this.isNextWeek(x.dueDate))).length > 0) {
      return { icon: faClipboard, class: "atividade-andamento", title: "A fazer" };
    }
    if (cat.filter(x => x.performed.length > 0)) {
      return { icon: faClipboardCheck, class: "atividade-feito", title: "Atividade Feita" };
    }

    return { icon: faClipboard, class: "atividade-andamento", title: "A fazer" };

  }

  getObjGrade(categoryId: number, schedule: Schedule): string {
    if (!schedule.activities) {
      return '-';
    }
    const activities = schedule.activities.filter((ac) =>
      ac.contents.filter((c) => c.platformId == this.platformId).length > 0
    );

    if (activities.length === 0) {
      return '-';
    }

    let cat = (categoryId === 9999) ?
      activities?.filter(x => x.categoryId === 6) :
      activities?.filter(x => x.categoryId === categoryId);



    if (cat && this.studentInfo.schoolPackage.packageId < 10 && (categoryId === 6 || categoryId === 9999)) {
      const numeracoes = _.uniq(cat.map((x) => parseInt(x.title.replace(/^\D+/g, '')))).sort((a, b) => a - b);

      if (numeracoes.length > 1) {
        if (categoryId === 6) {
          cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[0]);
        } else {
          cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[numeracoes.length - 1]);
        }
      }
      else if (numeracoes.length === 1) {
        if (categoryId === 6 && numeracoes[0] < 10) {
          cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[0]);
        }
        else if (categoryId === 9999 && numeracoes[0] >= 10) {
          cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[0]);
        }
        else {
          cat = [];
        }
      }
      else {
        cat = [];
      }

    }
    if (!cat || cat.length === 0) {
      return '-';
    }

    const referenceIds = (_.flatten(_.pluck(cat, 'contents'))
      .filter(x => x.platformId == this.platformId).map(x => parseInt(x.link.replace(/\D/g, ''))));


    const dones = this.filteredRecords.filter(x => referenceIds.includes(x.referenceId));

    if (dones.length === 0) {
      return 'Não feito';
    }

    const doneSum = dones.reduce((partial, x) => partial + x.gradeNumber, 0);

    const grade = (doneSum / referenceIds.length);

    return ((grade > 10) ? 10 : grade).toFixed(2);
  }


  getObjDone(categoryId: number, schedule: Schedule) {
    if (!schedule.activities) {
      return '-';
    }


    const activities = schedule.activities.filter((ac) =>
      ac.contents.filter((c) => c.platformId == this.platformId).length > 0 &&
      ac.performed.length > 0
    );

    let cat = (categoryId === 9999) ?
      activities?.filter(x => x.categoryId === 6) :
      activities?.filter(x => x.categoryId === categoryId);

    if (cat && this.studentInfo.schoolPackage.packageId < 10 && (categoryId === 6 || categoryId === 9999)) {
      const numeracoes = _.uniq(cat.map((x) => parseInt(x.title.replace(/^\D+/g, '')))).sort((a, b) => a - b);

      if (numeracoes.length > 1) {
        if (categoryId === 6) {
          cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[0]);
        } else {
          cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[numeracoes.length - 1]);
        }
      }
      else if (numeracoes.length === 1) {
        if (categoryId === 6 && numeracoes[0] < 10) {
          cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[0]);
        }
        else if (categoryId === 9999 && numeracoes[0] >= 10) {
          cat = cat.filter((x) => parseInt(x.title.replace(/^\D+/g, '')) === numeracoes[0]);
        }
        else {
          cat = [];
        }
      }
      else {
        cat = [];
      }

    }
    if (!cat || cat.length === 0) {
      return 'Não lido';
    }

    if (this.platformId == 62) {
      if (cat.length > 0) {
        const referenceIds = (_.flatten(_.pluck(cat, 'contents'))
          .filter(x => x.platformId == this.platformId && x.link.includes('curso/step-2/')).map(x => {
            return parseInt(x.link.split('/')[2]);
          }));

        const dones = this.filteredRecords.filter(x => referenceIds.includes(x.referenceId));

        return (dones.length > 0) ? dones[0].gradeNumber.toFixed(2) : 'Não feito';
      }
      return 'Não feito';
    }
    return (cat.length > 0) ? 'Lido' : 'Não lido';
  }



  isNextWeek(dueDate: Date): boolean {
    const week = moment(dueDate).week();
    return week > this.currentWeek;
  }

  isCurrentWeek(dueDate: Date): boolean {
    const week = moment(dueDate).week();
    return week === this.currentWeek;
  }

  isPastWeek(dueDate: Date): boolean {
    const week = moment(dueDate).week();
    return week < this.currentWeek;
  }

  filter(): void {
    this.isLoading = true;
    this.recordService.findFilteredPerStudent({
      profileIds: [this.user?.profileId as number],
      referenceIds: [],
      schoolsPackagesIds: [],
      platformId: this.platformId,
      year: new Date().getFullYear()
    })
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(result => {
        this.filteredRecords = result
      });
  }


}
