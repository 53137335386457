import { RecordService } from './../professor/service/record.service';
import { PeriodService } from './../atividades/service/period.service';
import { CategoryService } from './../shared/service/category.service';
import { ProfileSchoolPackageService } from './../shared/service/profileSchoolPackage.service';
import { AutenticacaoService } from './../login/service/autenticacao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from "@angular/core";
import { switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Period } from '../atividades/model/period.model';
import { RecordModel } from '../professor/model/record.model';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Schedule } from '../atividades/model/schedule.model';
import { Activity } from '../atividades/model/activity.model';
import { Content } from '../atividades/model/content.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  studentId: number = 0;
  performance: string = '0';
  participation: string = '0';
  proficiency: string = '0';

  isLoading: boolean = false;

  platformSelect: any[]  = [
    { id: 57, name: 'EduFit' },
    { id: 15, name: 'Hikes English' }
  ];
  platformId: number = 57;

  periods: Period[] = [];
  periodId: number = 0;

  statusId: number = 0;

  filteredPeriods: Period[] = [];
  schedules: Schedule[] = [];
  activities: Activity[] = [];

  records: RecordModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private autenticacaoService:  AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService,
    private periodService: PeriodService,
    private recordService: RecordService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.autenticacaoService
        .getLoggedUser()
        .pipe(
          switchMap( (u) => {
            if(u?.role !== 'Students') {
              this.router.navigate(['/mural']);
            }
            this.studentId = u?.profileId as number;
            const infoAluno$ = this.profileSchoolPackageService.findPerProfileId(this.studentId);
            const infoAtividades$ = this.periodService.findWithActivitiesPerProfileIdAndPlatform(this.studentId, this.platformId);
            const infoPeriods$ = this.periodService.findAll();
            return forkJoin([infoAluno$, infoAtividades$, infoPeriods$]);
          }),
          switchMap( (result) => {
            this.periods = result[1];

            this.schedules = (_.flatten(_.pluck(result[1], 'schedules')));
            this.activities = (_.filter((_.uniq(_.flatten(_.pluck(this.schedules, 'activities')))), (a) => a.contents.length > 0));

            return this.recordService.findFilteredPerStudent({
              profileIds: [this.studentId],
              referenceIds: [],
              schoolsPackagesIds: [],
              platformId: this.platformId,
              year: new Date().getFullYear()
            })
          })
        )
        .subscribe( (result) => {
          this.records = result;
          this.getPerformance();
          this.getParticipation();
          this.isLoading = false;
        });

  }

  changePlatform() {
    this.isLoading = true;
    forkJoin([
      this.periodService.findWithActivitiesPerProfileIdAndPlatform(this.studentId, this.platformId),
      this.recordService.findFilteredPerStudent({
        profileIds: [this.studentId],
        referenceIds: [],
        schoolsPackagesIds: [],
        platformId: this.platformId,
        year: new Date().getFullYear()
      })
    ])
    .subscribe( (result) => {
      this.periods = result[0];
      this.schedules = (_.flatten(_.pluck(result[0], 'schedules')));

      this.activities = (_.filter((_.uniq(_.flatten(_.pluck(this.schedules, 'activities')))), (a) => a.contents.length > 0));
      this.records = result[1];

      this.getPerformance();
      this.getParticipation();
      this.changeStatus();
      this.isLoading = false;
    })
  }

  changePeriod() {
    this.schedules = (this.periodId > 0) ?
      _.filter((_.flatten(_.pluck(this.periods, 'schedules'))), (x) => x.periodId == this.periodId) :
      _.flatten(_.pluck(this.periods, 'schedules'));

    this.activities = (_.filter((_.uniq(_.flatten(_.pluck(this.schedules, 'activities')))), (a) => a.contents.length > 0));
    this.getPerformance();
    this.getParticipation();
  }

  changeStatus() {
    if(this.statusId == 0) {
      this.changePeriod();
    }

    if(this.statusId == 1) {
      this.activities = this.activities.filter( (a) => this.getActivityRecord(a) !== null );
    }

    if(this.statusId == 2) {
      this.activities = this.activities.filter( (a) => this.getActivityRecord(a) === null);
    }
  }


  getActivityPeriod(activity: Activity) {
    const schedule = this.schedules.filter(s => s.id == activity.scheduleId);

    if(schedule.length === 0) {
      return '-';
    }

    const period = this.periods.filter(p => p.id == schedule[0].periodId);

    if(period.length === 0) {
      return '-';
    }

    return period[0].name;
  }


  getActivityRecord(activity: Activity): RecordModel | null {
    if(activity.contents.length === 0) {
      return null;
    }
    const referenceIds = activity.contents.filter( x => x.platformId == this.platformId).map(x => parseInt(x.link.replace(/\D/g,'')));

    if(referenceIds.length === 0) {
      return null;
    }

    const record = this.records.filter(x => x.referenceId === referenceIds[0]);

    if(record.length === 0) {
      return null;
    }

    return record[0];
  }

  getPerformance() {
    const dones = this.activities
                      .map( (a) => this.getActivityRecord(a) )
                      .filter( (r) => r !== null );

    const avg = ( dones.reduce( (acc, cur) => acc += cur?.gradeNumber || 0, 0) / dones.length ) * 10;

    this.performance = (!isNaN(avg)) ? avg.toFixed(2) : '0';

    this.proficiency =  ( Number(this.performance) * Number(this.participation) ).toFixed(2);
  }

  getParticipation() {
    const dueActivities = this.activities
                              .filter( (a) => moment(a.dueDate).isBefore(moment()));

    const doneActivities = dueActivities.filter( (a) => this.getActivityRecord(a) !== null );

    const part = ( doneActivities.length / dueActivities.length );

    this.participation =  (!isNaN(part)) ? (part * 100).toFixed(2) : '0';


    this.proficiency =  ( ( ( Number(this.performance) / 100 ) * ( Number(this.participation) / 100 ) ) * 100).toFixed(2);

  }


}
