import { DesempenhoService } from './desempenho.service';
import { Component, OnInit } from '@angular/core';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';

import {ChartType} from 'angular-google-charts'

@Component({
  selector: 'app-desempenho',
  templateUrl: './desempenho.component.html',
  styleUrls: ['./desempenho.component.css']
})
export class DesempenhoComponent implements OnInit {

  faAngleRight = faAngleRight;
  materiasDesempenho!:any[]


  title = 'Gráfico';
  type = ChartType.BarChart;
  data = [
    ['Biologia', 5.0, 10.0, 6.8],
    ['Física', 5.0, 10.0, 6.8],
    ['Quimica', 5.0, 10.0, 6.8],
    ['Matemática', 5.0, 10.0, 6.8],
    ['Português e Literatura', 5.0, 10.0, 6.8],
    ['Prod.Textual', 5.0, 10.0, 6.8] ,
    ['Geografia', 5.0, 10.0, 6.8],
    ['História', 5.0, 10.0, 6.8],
    ['Filosofia', 5.0, 10.0, 6.8],
    ['Sociologia', 5.0, 10.0, 6.8],
    ['Espanhol', 5.0, 10.0, 6.8],
    ['Ingles', 5.0, 10.0, 6.8],

 ];
 columnNames = ['Disciplinas', 'Participação', 'Desempenho', 'Proficiência'];
 options = {
   colors: ['#096043','#c0e2a6','#96d22b']
};
// width = 1080;
height = 1000;

  constructor(private desempenhoService: DesempenhoService) { }



  ngOnInit(): void {

    this.getMaterias()
  }

  getMaterias(){
    this.materiasDesempenho = this.desempenhoService.getMaterias();
  }
}
