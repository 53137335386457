import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { AccessTokenService } from './login/service/accessToken.service';
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private accessTokenService: AccessTokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!!this.accessTokenService.token) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.accessTokenService.token
        }
      });
    }
    return next.handle(req);
  }
}
