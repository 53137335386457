import { SchoolPackage } from './../shared/model/schoolPackage.model';
import { Category } from './../shared/model/category.model';
import { AutenticacaoService } from './../login/service/autenticacao.service';
import { User } from './../login/model/user.model';
import { DisciplinaService } from './../disciplina/disciplina.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { faUserCircle, faTasks, faBars, faChartLine, faListAlt, faBook, faHeadset, faQuestionCircle, faPeopleCarry, faPeopleArrows, faPersonBooth, faUsers, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { fromEvent, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { ProfileSchoolPackage } from '../shared/model/profileSchoolPackage.model';
import { CategoryService } from '../shared/service/category.service';
import { ProfileSchoolPackageService } from '../shared/service/profileSchoolPackage.service';
import * as _ from 'underscore';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  @ViewChild('modalPerfil', {static: false}) modalPerfil: ElementRef<HTMLElement> | undefined;
  // Icones FontAwesome
  faDashboard = faTachometerAlt;
  faUserCircle = faUserCircle;
  faBars = faBars;
  faTasks = faTasks;
  faChartLine = faChartLine;
  faListAlt = faListAlt;
  faBook = faBook;
  faHeadset = faHeadset;
  faPeople = faUsers;
  faQuestionCircle = faQuestionCircle;

  disciplinas: any;
  categories: Category[] = [];
  usuario: User | null = null;
  usuarioProfessor: boolean = false;
  profileSchoolPackages: ProfileSchoolPackage[] = [];

  constructor(
    private disciplinaService: DisciplinaService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private autenticacaoService: AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService) { }


  ngOnInit() {
    this.disciplinas = this.disciplinaService.getDisciplinas();
    // this.categoryService.findAll().subscribe(c => this.categories = c);
    this.autenticacaoService
        .getLoggedUser()
        .pipe(
          tap( (u) => this.profileSchoolPackageService
            .findPerProfileId(u?.profileId as number)
            .subscribe( psp => this.profileSchoolPackages  = psp.filter(x => x.schoolPackage.name !== 'Galera Cult') )
          )
        )
        .subscribe( u => this.usuario = u);
  }

  logout() {
    this.autenticacaoService.logout();
    this.router.navigate(['/login']);
    this.modalPerfil?.nativeElement.click();
  }

  getEscolas(): SchoolPackage[] {
    return _.uniq(_.pluck(this.profileSchoolPackages, 'schoolPackage'), x => x.school.name );
  }

  getTurmas(): SchoolPackage[] {
    return _.sortBy(_.pluck(this.profileSchoolPackages, 'schoolPackage'), x => x.name );
  }
}
