
<main>
  <div class="container">
    <h3 class="titulo-filtro">Filtre sua busca</h3>

    <section class="d-flex flex-wrap filtro-busca">

      <div class="d-flex mb-3 mt-3">

        <p class="tag-select mr-2">Turma:</p>
        <select (change)="filter()" [(ngModel)]="schoolPackageId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:150px;">
          <option value="0" class="text-select">Todas</option>
          <option [value]="sp.id" *ngFor="let sp of schoolPackages" class="text-select">{{sp.name}}</option>
        </select>

      </div>

      <div class="d-flex mb-3 mt-3">

        <p class="tag-select mr-2">Plataforma:</p>
        <select (change)="filter()" [(ngModel)]="platformId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:150px;">
          <option value="0" class="text-select">Todas</option>
          <option [value]="p.id" *ngFor="let p of platformSelect" class="text-select">{{p.name}}</option>
        </select>
      </div>


      <div class="input-group input-group mb-4 ml-4 mt-4 busca-aluno tag-select">
        <input [(ngModel)]="searchName" type="text" #search class="form-control mr-2 tag-select" aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm" placeholder="Buscar pelo nome do aluno">
        <button type="button" class="btn btn-success mr-3" (click)="filter()">Buscar</button>
      </div>

    </section>

    <h3 class="mt-5">Lista de Tarefas Feitas</h3>
    <div class="container scroll-table">
      <div class="row text-center flex-nowrap" *ngIf="isLoading === false">
        <table class="table table-striped table-hover mt-2">
          <thead>
            <tr>
              <th scope="col">Aluno</th>
              <th scope="col">Turma</th>
              <th scope="col">Plataforma</th>
              <th scope="col">Tarefa</th>
              <th scope="col">Nota / Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of records">
              <td>{{r.profile.name }} {{ r.profile.lastName }}</td>
              <td>{{r.schoolPackage.name}}</td>
              <td>{{r.platform.name}}</td>
              <td>{{r.referenceName}}</td>
              <td>{{(r.platform.id === 57 || r.platform.id == 62 || r.platform.id === 15) ? r.gradeNumber : 'Lido' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button type="button" class="btn btn-success mt-5 mb-4 ml-5" (click)="nextPage()">Carregar mais registros</button>
  </div>

</main>
