import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Mural } from './Mural';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MuralService {

  getAtividadesMural(){
    return [
    
        {
            id: 1,
            disciplina: "História",
            descricao: "Breve descrição do que foi postado",
            data: "30/08/2021",
            diaSemana: "Segunda Feira",
            professor: "Professor",
            imagem: "../../assets/images/12.libro.jpg",
            status: "",
            aula: "Aula 1.1"
        },
        {
            id: 2,
            disciplina: "Matemática",
            descricao: "Breve descrição do que foi postado",
            data: "30/08/2021",
            diaSemana: "Segunda Feira",
            professor: "Professor",
            imagem: "../../assets/images/matematica.jpg",
            status: "",
            aula: "1.2"
        },
        {
            id: 3,
            disciplina: "Biologia",
            descricao: "Breve descrição do que foi postado",
            data: "30/08/2021",
            diaSemana: "Segunda Feira",
            professor: "Professor",
            imagem: "../../assets/images/biologia.jpg",
            status: "",
            aula: "2.1"
        }
    
    ]
  }

 


  // getAtividade(): Observable <Mural>{
  //   return this.http.get<Mural>("http://localhost:3000/atividade")
  // }

 
  constructor(private http: HttpClient) { }

  ngOnInit() {

  
    
  }
}
