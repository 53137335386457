<div class="container mt-4">
    <div class="row">
        <div class="container-titulo">
            <p >Desempenho em Porcentagem</p>
        </div>        
        <div class="proficiencia lpl col-4" *ngFor="let materia of materiasDesempenho" >
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div class="descricao-disiplina">
                <p class="circle-title">{{materia.nome}}</p>
                <span class="circle-saibaMais"><a class="links" [routerLink]="['/disciplina/1/nota']">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>

        <!-- <div class="proficiencia lpt col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">LPT</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>

        <div class="proficiencia lit col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">LIT</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>

        <div class="proficiencia ing col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">ING</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>

        <div class="proficiencia mat col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">MAT</p>
                <span class="circle-saibaMais"><a class="links" [routerLink]="['/disciplina/1/nota']">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>


        <div class="proficiencia fis col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">FIS</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>

        <div class="proficiencia qui col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">QUI</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>

        <div class="proficiencia bio col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">BIO</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>


        <div class="proficiencia geo col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">GEO</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>


        <div class="proficiencia his col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">HIS</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>

        <div class="proficiencia fil col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">FIL</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div>

        <div class="proficiencia soc col-4">
            <div class="circle-desempenho">
                <p class="circle-texto">50<span class="circle-porcentagem">%</span></p>
            </div>
            <div>
                <p class="circle-title">SOC</p>
                <span class="circle-saibaMais"><a class="links">Acessar</a><fa-icon [icon]="faAngleRight" class="icone-seta"></fa-icon>
                </span>
            </div>
        </div> -->
    </div>


</div>

<google-chart #chart [title]="title" [type]="type" [columns]="columnNames" [data]="data" [options]="options"
     [height]="height" style="width: 95%;">
</google-chart>