import { Activity } from './../../atividades/model/activity.model';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import * as _ from 'underscore';
import { PeriodService } from 'src/app/atividades/service/period.service';
import { Period } from 'src/app/atividades/model/period.model';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

const DAY_MS = 60 * 60 * 24 * 1000;

@Component({
  selector: 'agenda-calendario',
  templateUrl: './agenda-calendario.component.html',
  styleUrls: ['./agenda-calendario.component.css']
})
export class AgendaCalendarioComponent implements OnChanges {
  dates: Date[] | null = [];
  days: string[] = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  date = new Date();
  isLoading: boolean = false;
  weeks: any[] = [];
  weekNames: any[] = [];
  periods: Period[] = [];

  @Input() currentMonth: number = 0;
  @Input() profileId: number = 0;
  @Output() selected = new EventEmitter();
  @Output() changeWeeks = new EventEmitter();
  @Output() weekSelected = new EventEmitter();

  constructor(
    private periodService: PeriodService,
    private datePipe: DatePipe
  ) {
    this.dates = this.getCalendarDays(this.date);

    if(this.dates !== null) {
      this.weeks =  _.chunk(this.dates, 7);
    }
  }

  setSpecific(month: number) {
    this.date = new Date(this.date.getFullYear(), month,1);
    this.dates = this.getCalendarDays(this.date);
  }

  setMonth(inc: number) {
    const [year, month] = [this.date.getFullYear(), inc];

    this.date = new Date(year, inc,1);


    this.dates = this.getCalendarDays(this.date);

    if (this.dates !== null) {
      this.weeks =  _.chunk(this.dates, 7);
    }
  }


  isSameMonth(date: Date) {
    return date.getMonth() === this.date.getMonth() && date.getDay() !== 6 && date.getDay() !== 0;
  }

  private getCalendarDays(date: Date) {
    const calendarStartTime = this.getCalendarStartDay(date);
    const hourAdjs = 60 * 60 * 2 * 1000;

    if (!calendarStartTime) {
      return null;
    }

    return this.range(0, 41)
      .map( (num: number) => new Date(calendarStartTime.getTime() + hourAdjs + DAY_MS * num));
  }

  private getCalendarStartDay(date: Date) {
    const [year, month] = [date.getFullYear(), date.getMonth()];
    const firstDayOfMonth = new Date(year, month, 1).getTime();

    return this.range(1, 7)
      .map( (num: number) => new Date(firstDayOfMonth - DAY_MS * num))
      .find( (dt: Date) => dt.getDay() === 0);
  }

  private range(start: number, end: number) {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.profileId && changes.profileId.currentValue !== changes.profileId.previousValue) {
      this.isLoading = true;
      this.periodService
        .findWithActivitiesPerProfileId(changes.profileId.currentValue)
        .pipe(finalize( () => this.isLoading = false))
        .subscribe( (result) => {
          this.periods = result;
        } );
    }
  }

  getNomeSemana(week: any[]): string {
    const schedules = (_.flatten(_.pluck(this.periods, 'schedules')));
    for(let i = 0; i < schedules.length; i++) {
      //const activity = (_.min(schedules[i].activities, (a) => new Date(a.dueDate))) as Activity;
      //const minDate = new Date(activity.dueDate);
      const dt = new Date(this.getMostFrequentDueDate(schedules[i].activities));

      if(dt >= week[0] && dt <= week[week.length - 1]) {
        return schedules[i].name;
      }
    }

    return ( this.datePipe.transform(week[1], 'dd/MM/yyyy') || '' ) + ' - ' +
           ( this.datePipe.transform(week[week.length - 2], 'dd/MM/yyyy'));
  }

  selectWeek(index: number) {
    this.weekSelected.emit(index);
  }

  getMostFrequentDueDate(activities:Activity[]) {
    const dates = _.map(activities, activity => activity.dueDate);
    const dateCount = _.countBy(dates);
    const mostFrequentDate = _.max(_.keys(dateCount), date => dateCount[date]);

    return mostFrequentDate;
}

}
