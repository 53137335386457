<main>
  <div class="container">

    <h3 class="titulo-filtro">Filtre sua busca</h3>

    <section class="d-flex flex-wrap filtro-busca">

      <div class="d-flex mb-3 mt-3">

        <p class="tag-select mr-2">Turma:</p>
        <select (change)="filter()" [(ngModel)]="schoolPackageId"
          class="atividades-contagem-filtro text-select tag-select mr-2" style="width:150px;">
          <option value="0" class="text-select">Todas</option>
          <option [value]="sp.id" *ngFor="let sp of schoolPackages" class="text-select">{{sp.name}}</option>
        </select>
      </div>


      <div class="input-group input-group mb-4 ml-4 mt-4 busca-aluno tag-select">
        <input [(ngModel)]="searchName" type="text" #search class="form-control mr-2 tag-select"
          aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
          placeholder="Buscar pelo nome do aluno">
        <button type="button" class="btn btn-success mr-3" (click)="filter()">Buscar</button>
      </div>
    </section>
    <h3 class="mt-5">Lista de Alunos</h3>
    <div class="container scroll-table">
      <div class="row text-center flex-nowrap" *ngIf="isLoading === false">
        <table class="table table-striped table-hover mt-2">
          <thead>
            <tr>
              <th scope="col">Aluno</th>
              <th scope="col">Email</th>
              <th scope="col">Turma</th>
              <th scope="col">Segmento</th>
              <th scope="col">#</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let std of students">
              <td>{{std.name + " " + std.lastName}}</td>
              <td>{{std.userName}}</td>
              <td>{{std.profileSchoolPackage[0].schoolPackage.name}}</td>
              <td>{{std.profileSchoolPackage[0].schoolPackage.package.description}}</td>
              <td>
                <button [routerLink]="['/alunos/visao', std.id]" type="button" class="btn btn-success mb-2">Ver Situação</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button type="button" class="btn btn-success mt-5 mb-4 ml-5" (click)="nextPage()">Carregar mais alunos</button>

  </div>
</main>
