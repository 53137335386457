<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid container-principal">
        <button data-bs-toggle="modal" data-bs-target="#exampleModal" class="botao-collapso">
            <a>
                <span>
                    <fa-icon [icon]='faBars' class="botao-collapso--icone"></fa-icon>
                </span>
            </a>
        </button>
        <!-- Modal -->
        <div class="modal left fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">iClassroom</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul class="navbar-nav">
                            <li class="nav-item" *ngIf="usuario?.role === 'Students'">
                              <a href="/dashboard" class="nav-link nav-link--estilo">
                                  <fa-icon [icon]="faDashboard" class="nav-bar--icones"></fa-icon>
                                  Dashboard
                              </a>
                            </li>
                            <li class="nav-item">
                                <a href="/mural" class="nav-link nav-link--estilo">
                                    <fa-icon [icon]="faListAlt" class="nav-bar--icones"></fa-icon>
                                    Mural
                                </a>
                            </li>

                            <!-- <li class="nav-item">
                                <a href="/atividades" class="nav-link nav-link--estilo">
                                    <fa-icon [icon]="faTasks" class="nav-bar--icones"></fa-icon>
                                    Agenda de Atividades
                                </a>
                            </li> -->

                            <li class="nav-item">
                              <a href="/agenda" class="nav-link nav-link--estilo">
                                  <fa-icon [icon]="faTasks" class="nav-bar--icones"></fa-icon>
                                  Agenda de Atividades
                              </a>
                          </li>
                            <!-- <li class="nav-item ">
                                <a [routerLink]="['/desempenho']" class="nav-link nav-link--estilo">
                                    <fa-icon [icon]="faChartLine" class="nav-bar--icones"></fa-icon>
                                    Desempenho
                                </a>
                            </li> -->


                        </ul>
                        <ul class="navbar-nav border-style">
                            <li class="nav-item">
                                <a href="/ao-vivo" class="nav-link nav-link--estilo">
                                    <fa-icon [icon]="faHeadset" class="nav-bar--icones" ></fa-icon>
                                    Ao vivo
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="usuario?.role !== 'Administrators' && usuario?.role !== 'Teachers'">
                              <a href="/aluno/notas" class="nav-link nav-link--estilo">
                                <fa-icon [icon]="faBook" class="nav-bar--icones"></fa-icon>
                                Notas
                              </a>
                            </li>
                            <li class="nav-item dropdown" *ngIf="usuario?.role === 'Administrators' || usuario?.role === 'Teachers'">
                              <a class="nav-link dropdown-toggle nav-link--estilo" href="#"
                                  id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <fa-icon [icon]="faBook" class="nav-bar--icones"></fa-icon>
                                  Professor
                              </a>
                              <ul class="dropdown-menu item-dropdown-disciplinas" aria-labelledby="navbarDropdownMenuLink">
                                  <li><a class="dropdown-item nav-link--estilo" href="/notas/registro">Registro de Notas</a></li>
                                  <li><a class="dropdown-item nav-link--estilo" href="/alunos">Lista de Alunos</a></li>
                                  <li><a class="dropdown-item nav-link--estilo" href="/turmas/visao">Lista de Turmas</a></li>
                                  <li><a class="dropdown-item nav-link--estilo" href="/esforco">Percentual de Esforço</a></li>
                                  <li><a class="dropdown-item nav-link--estilo" href="/performance">Tabela de Performance</a></li>
                              </ul>
                            </li>
                            <!-- <li class="nav-item">
                                <a href="" class="nav-link nav-link--estilo">
                                    <fa-icon [icon]="faQuestionCircle" class="nav-bar--icones"></fa-icon>
                                    Ajuda
                                </a>
                            </li> -->

                        </ul>
                    </div>
                    <!-- <div class="modal-footer"> -->
                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <span class="navbar-title">iClassroom</span>
        <span>
            <a data-bs-toggle="modal" #modalPerfil data-bs-target="#modalPerfil"><fa-icon [icon]="faUserCircle" class="icon-profile"></fa-icon></a>
        </span>
    </div>
</nav>

<div class="modal right fade" id="modalPerfil" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header modal-header-perfil">
                <div class="modal-title" id="exampleModalLabel">
                    <p class="modal-title-nome-aluno">{{ usuario?.username }}</p>
                    <p class="modal-title-email">{{ usuario?.email }}</p>
                </div>
                <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->

            </div>
            <div class="modal-body modal-body-perfil">

              <p class="modal-body-texto">Escola:
                <span *ngFor="let sp of getEscolas(); let i = index;">
                  <span>{{ sp?.school?.name }}</span>
                  <span *ngIf="i < getEscolas().length - 1">;</span>
                </span>
              </p>
              <p class="modal-body-texto">Turma:
                <span *ngFor="let sp of getTurmas(); let i = index;">
                  <span>
                    {{ sp.name }}
                  </span>
                  <span *ngIf="i < getTurmas().length - 1">;</span>
                </span>
              </p>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <a [routerLink]="['/perfil']" class="btn btn-primary">Configure sua Conta</a>
                <a  [routerLink]="['/enturmacao']" class="btn btn-primary" *ngIf="usuarioProfessor">Adicionar Aluno</a>
                <a  class="btn btn-primary" (click)="logout()" >Sair da Conta</a>
            </div>
        </div>
    </div>
</div>
