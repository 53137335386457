import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { EnturmacaoService } from './enturmacao.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-enturmacao',
  templateUrl: './enturmacao.component.html',
  styleUrls: ['./enturmacao.component.css']
})
export class EnturmacaoComponent implements OnInit {

  faSearch = faSearch;

  dados!: any[];
  dadoDesenturmar!: any[]
  id!: number;
  filtro!: string

  turma!: number;
  turmas = [
    3001,
    3002,
    3004
  ]

  constructor(private enturmacaoService: EnturmacaoService) { }

  ngOnInit(): void {

    this.dados = this.enturmacaoService.getDadosEnturmacao();
    this.validaBotaoEnturmar();
  }

  validaBotaoEnturmar() {}

  desenturmaAluno(evento: any) {
    evento.turma = ''
  }

  pegaId(dado: any) {
    this.id = dado.id;
  }
  enturmarAluno() {
    this.dados.filter(dado => {
      if (dado.id == this.id) {
        dado.turma = this.turma
      }
    })
  }


  selecionaTurma() {
    return this.turma
  }

  filtraAlunos() {
    if (this.dados.length === 0 || this.filtro === undefined || this.filtro.trim() === '') {
      return this.dados;
    }

    return this.dados.filter((v) => {
      if (v.nome.indexOf(this.filtro) >= 0 || v.turma.indexOf(this.filtro) >= 0 || v.email.indexOf(this.filtro) >= 0) {
        return true;
      }
      return false
    })

  }



}

