import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Category } from '../model/category.model';
import { CoursePackage } from '../model/course-package.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) {}

  findAll(platform: string): Observable<Category[]> {
    return this.http
      .get<Category[]>(environment.API_PATH + 'core/categories/platform/' + platform);
  }

  findPerPackageId(packageId: number): Observable<Category[]> {
    return this.http
      .get<Category[]>(environment.API_PATH + 'hub/activity/perpackage/' + packageId);
  }
}
