import { ProfileSchoolPackageService } from './../shared/service/profileSchoolPackage.service';
import { AutenticacaoService } from './../login/service/autenticacao.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import {faEdit} from '@fortawesome/free-solid-svg-icons';
import { User } from '../login/model/user.model';
import { ProfileSchoolPackage } from '../shared/model/profileSchoolPackage.model';
import { SchoolPackage } from '../shared/model/schoolPackage.model';
import { finalize, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  faEdit = faEdit;

  @ViewChild("nome") nome!: ElementRef;
  @ViewChild("btnCloseModal") btnCloseModal!: ElementRef;
  usuario: User | null = null;
  profileSchoolPackages: ProfileSchoolPackage[] = [];
  newPassword: string = '';
  confPassword: string = '';

  constructor(
    private autenticacaoService: AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.autenticacaoService
        .getLoggedUser()
        .pipe(
          tap ( (u) => this.profileSchoolPackageService
            .findPerProfileId(u?.profileId as number)
            .subscribe(psp => this.profileSchoolPackages = psp.filter(x => x.schoolPackage.name !== 'Galera Cult'))
          )
        )
        .subscribe( u => this.usuario = u);
  }

  resetarSenha() {
    this.btnCloseModal.nativeElement.click();
    this.autenticacaoService
    .reset(this.usuario?.email as string, this.newPassword)
    .subscribe( (_) => this.toastrService.success("Senha Resetada com Sucesso!"));
  }

  senhasSaoValidas(): boolean {
    return ( this.usuario?.email !== null &&
      this.newPassword !== "" &&
      this.confPassword !== "" &&
      this.confPassword === this.newPassword );
  }

  getEscolas(): SchoolPackage[] {
    return _.uniq(_.pluck(this.profileSchoolPackages, 'schoolPackage'), x => x.school.name );
  }

  getTurmas(): SchoolPackage[] {
    return _.sortBy(_.pluck(this.profileSchoolPackages, 'schoolPackage'), x => x.name );
  }

}
