import { RecordModel } from './../model/record.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { RecordFilter, StudentRecordFilter } from '../model/record.filter.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecordService {
  constructor(private http: HttpClient) {}

  findFiltered(params: RecordFilter): Observable<RecordModel[]> {
    return this.http
               .post<RecordModel[]>(environment.API_PATH + 'performance/records/filter', params);
  }

  findFilteredPerStudent(params: StudentRecordFilter): Observable<RecordModel[]> {
    return this.http
               .post<RecordModel[]>(environment.API_PATH + 'performance/records/filter/perstudent', params);
  }


}
