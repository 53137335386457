import { AutenticacaoService } from './../login/service/autenticacao.service';
import { Component } from "@angular/core";
import { Router } from '@angular/router';
import { finalize, switchMap } from 'rxjs/operators';
import { ProfileSchoolPackageService } from '../shared/service/profileSchoolPackage.service';
import { StudentFilter } from './model/student.filter.model';
import { Student } from './model/student.model';
import * as _ from 'underscore';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-aluno',
  templateUrl: './aluno.component.html',
  styleUrls: ['./aluno.component.css']
})
export class AlunoComponent {
  students: Student[] = [];
  studentsFilter!: StudentFilter;
  schoolPackages: any[] = [];
  schoolPackageId: number = 0;
  searchName: string = "";
  isLoading = true;

  constructor(
    private router: Router,
    private autenticacaoService: AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.autenticacaoService
        .getLoggedUser()
        .pipe(
          switchMap( (u) => {
            if(u?.role === 'Students') {
              this.router.navigate(['/mural']);
            }

            return this.profileSchoolPackageService.findPerProfileId(u?.profileId as number);
          }),
          switchMap( (psp) => {
            this.schoolPackages = psp
                .filter(x => x.schoolPackage.name !== 'Galera Cult')
                .map(x => ({ id: x.schoolsPackagesId, name: x.schoolPackage.name }));

            this.schoolPackages = _.sortBy(this.schoolPackages, (x) => parseInt(x.name) || 0);
            this.studentsFilter =
            {
              name: "",
              page: 1,
              schoolPackagesIds: psp
                .filter(x => x.schoolPackage.name !== 'Galera Cult')
                .map(x => x.schoolsPackagesId)
            };

            return this.profileSchoolPackageService.findFiltered(this.studentsFilter);
          })
        )
        .subscribe( (std) => {
          this.isLoading = false;
          this.students = std;
        })
  }

  filter(): void {
    this.isLoading = true;
    this.studentsFilter.page = 1;
    this.studentsFilter.name = this.searchName;
    this.studentsFilter.schoolPackagesIds = this.schoolPackages
        .filter(x => this.schoolPackageId == 0 || x.id == this.schoolPackageId)
        .map(x => x.id);
    this.profileSchoolPackageService
        .findFiltered(this.studentsFilter)
        .pipe( finalize( () => this.isLoading = false))
        .subscribe( (std) => this.students = std);

  }

  nextPage(): void {
    this.studentsFilter.page++;
    this.profileSchoolPackageService
        .findFiltered(this.studentsFilter)
        .pipe( finalize( () => this.isLoading = false))
        .subscribe( (std) => this.students = this.students.concat(std));
  }

}
