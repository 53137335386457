<div class="container container-principal mx-auto">
    <div class="row">
        <h1 class="text-center titulo-principal">Informações da conta</h1>
    </div>
    <div class="row mx-auto mt-3">

        <div class="card">
            <div class="card-body">
                <div class="nome campo-informacoes">
                    <div>
                        <p class="mb-0 titulo-box">Nome
                          <!--<fa-icon [icon]="faEdit" data-bs-toggle="modal" data-bs-target="#modalNome"></fa-icon>-->
                        </p>
                    </div>
                    <div>
                        <div class="texto-box">{{ usuario?.username }}</div>
                    </div>
                </div>

                <div class="Escola campo-informacoes">
                    <div>
                        <p class="mb-0 titulo-box">Escola</p>
                    </div>
                    <div>
                        <div class="texto-box">
                          <span *ngFor="let sp of getEscolas(); let i = index;">
                            {{ sp?.school?.name }}
                            <span *ngIf="i < getEscolas().length - 1">;</span>
                          </span>
                        </div>
                    </div>
                </div>

                <div class="turma campo-informacoes">
                    <div>
                        <p class="mb-0 titulo-box">Turma</p>
                    </div>
                    <div>
                        <div class="texto-box">
                          <span *ngFor="let sp of getTurmas(); let i = index;">
                            {{ sp?.name }}
                            <span *ngIf="i < getTurmas().length - 1">;</span>
                          </span>
                        </div>
                    </div>
                </div>

                <div class="email campo-informacoes">
                    <div>
                        <p class="mb-0 titulo-box">E-mail</p>
                    </div>
                    <div>
                        <div class="texto-box">{{ usuario?.email }}</div>
                    </div>
                </div>

                <div class="senha campo-informacoes">
                    <div>
                        <p class="mb-0 titulo-box">Senha <fa-icon [icon]="faEdit" data-bs-toggle="modal"
                                data-bs-target="#modalSenha"></fa-icon>
                        </p>
                    </div>
                    <div>
                        <div class="texto-box">********</div>
                    </div>
                </div>
                <!-- MODAL SENHA -->
                <div class="modal fade" id="modalSenha" tabindex="-1" aria-labelledby="modalSenhaLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalSenhaLabel">Trocar senha</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" #btnCloseModal
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="senha campo-informacoes">
                                    <div>
                                        <p class="mb-1 titulo-box">Nova Senha</p>
                                    </div>
                                    <div>
                                        <input type="password" [(ngModel)]="newPassword" class="input-estilo-padrao mb-1">
                                    </div>
                                    <div>
                                        <p class="mb-1 titulo-box">Confirme a nova senha</p>
                                    </div>
                                    <div>
                                        <input type="password" [(ngModel)]="confPassword" class="input-estilo-padrao mb-1">
                                    </div>
                                </div>
                                <div class="d-grid gap-2 col-6 mx-auto div-btn-enviar-modal">
                                    <button class="btn btn-enviar-modal" type="button" [disabled]="!senhasSaoValidas()" (click)="resetarSenha()">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- MODAL NOME -->

                <!-- <div class="modal fade" id="modalNome" tabindex="-1" aria-labelledby="modalNomeLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalNomeLabel">Trocar nome</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="senha campo-informacoes modal-input">
                                    <div>
                                        <p class="mb-1 titulo-box">Nome Completo</p>
                                    </div>
                                    <div>
                                        <input type="text" id="nome" #nome class="input-estilo-padrao">
                                    </div>
                                </div>
                                <div class="d-grid gap-2 col-6 div-btn-enviar-modal ">
                                    <button class="btn btn-enviar-modal" type="button"
                                        data-bs-dismiss="modal">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->


            </div>
        </div>

    </div>
