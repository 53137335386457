<div style="margin-bottom:5rem;">
  <div class="box-calendario-semanal" *ngFor="let a of weekActivities">
    <div>
      <div class="box-calendario-semanal-disciplinas" [routerLink]="['/atividades/atividade', a.id]">
        <span class="box-calendario-semanal-disciplinas-texto">
          {{ a.title }}
        </span>
        <fa-icon [icon]=faClipboardCheck class="atividade-feito" *ngIf="a.performed.length > 0" title="Atividade Feita"></fa-icon>
        <fa-icon [icon]=faExclamationTriangle class="atividade-pendente" *ngIf="a.performed.length === 0 && isPastWeek(a.dueDate)" title="Atividade Pendente"></fa-icon>
        <fa-icon [icon]=faClipboard class="atividade-andamento " *ngIf="a.performed.length === 0 && (isCurrentWeek(a.dueDate) || isNextWeek(a.dueDate))" title="Atividade a Fazer"></fa-icon>
      </div>
    </div>
  </div>
</div>
