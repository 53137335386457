<section class="container">

  <div class="box-atividades-contagem new-version-box" *ngIf="!isLoading" style="
    background-color: #eee; cursor: pointer;
" (click)="optVersionIn()">
    <div class="container contagem-atividades">
      <div class="row">
        <p style="
          margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;">
          O iClassroom está de cara nova! Clique aqui e teste a versão beta.
        </p>
      </div>
    </div>
  </div>

  <div class="box-atividades-contagem" *ngIf="!isLoading">


    <div class="container contagem-atividades">
      <div class="row">

        <div class="input-group input-group mb-4 ml-4 mt-4 busca-aluno text-select">
          <input type="text" [(ngModel)]="search" class="form-control mr-2 text-select"
            aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
            placeholder="Buscar pelo nome da atividade ou pelo número da aula">
          <button type="button" class="btn btn-success mr-3 text-select"
            (click)="reloadActivitiesSearch()">Buscar</button>
        </div>
        <div class=" col atividades-atribuidas">
          <span class="atividades-contagem-numero">{{ this.atividades.length }}</span>
          <span class="atividades-contagem-descricao">Atividades Carregadas</span>
        </div>
        <div class=" col atividades-pendentes" *ngIf="groupedPackages.length > 1">
          <select [(ngModel)]="schoolPackageId" class="atividades-contagem-filtro text-select"
            (change)="reloadActivitiesSchoolPackage()" style="width:250px;">
            <option value="0" class="text-select">Todas as turmas:</option>

            <option [value]="p.id" *ngFor="let p of groupedPackages" class="text-select">
              {{p.name}}
            </option>
          </select>
        </div>
        <div class=" col atividades-pendentes" *ngIf="groupedCategories.length > 1">
          <select [(ngModel)]="categoryId" class="atividades-contagem-filtro text-select"
            (change)="reloadActivitiesCategories()" style="width:250px;">
            <option value="0" class="text-select">Todas as matérias:</option>
            <option [value]="p.id" *ngFor="let p of groupedCategories" class="text-select">
              {{p.name}}
            </option>
          </select>
        </div>

      </div>
    </div>
  </div>

  <div class="box-mural" *ngIf="!isLoading">
    <div class="card mb-3 mt-3 card-mural" *ngFor="let atividade of atividadesFiltradas"
      [routerLink]="['/atividades/atividade', atividade.id]" style="cursor: pointer;">
      <a [href]="'/atividades/atividade/' + atividade.id" style="color:black; text-decoration:none;">
        <div class="card-imge" [style.background-image]="'url(' + atividade.image + ')'">
          <!--<img [src]="atividade.image" class="card-image-resolucao">--></div>
        <div class="card-body">
          <h5 class="card-title"></h5>
          <p class="card-text"><span class="card-text-title">{{atividade.package.name}} -
              {{atividade.category.name}}</span><span [innerHtml]="atividade.message"></span></p>

        </div>
        <div class="card-footer">
          <p class="card-text" *ngIf="atividade.performed.length === 0"><small class="text-muted">Data Limite da Tarefa
              - {{atividade.dueDate | date:'dd/MM/yyyy'}}</small></p>
          <p class="card-text" *ngIf="atividade.performed.length > 0"><small class="text-muted">Marcado como feito em:
              {{ atividade.performed[0]?.created | date:'dd/MM/yyyy' }}</small></p>

        </div>
      </a>
    </div>

    <button type="button" class="btn btn-success mt-5 mb-4 ml-5" [disabled]="loadingMoreActivities == true"
      (click)="nextPage()">Carregar mais atividades</button>

  </div>

</section>
