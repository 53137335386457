import { DashboardComponent } from './dashboard/dashboard.component';
import { AgendaComponent } from './agenda/agenda.component';
import { TurmaEsforcoComponent } from './turma-esforco/turma-esforco.component';
import { AlunoNotasComponent } from './aluno-notas/aluno-notas.component';
import { TurmaComponent } from './turma/turma.component';
import { AlunoIndividualComponent } from './aluno-individual/aluno-individual.component';
import { AlunoComponent } from './aluno/aluno.component';
import { ProfessorComponent } from './professor/professor.component';
import { EnturmacaoComponent } from './enturmacao/enturmacao.component';
import { EsqueceuSenhaComponent } from './esqueceu-senha/esqueceu-senha.component';
import { LoginComponent } from './login/login.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MuralComponent } from './mural/mural.component';
import { DesempenhoComponent } from './desempenho/desempenho.component';
import { LoginGuard } from './login/guard/login.guard';
import { AuthGuard } from './login/guard/auth.guard';
import { HomeComponent } from './home/home.component';
import { PerformanceComponent } from './performance/performance.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'mural',
    component: MuralComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'desempenho',
    component: DesempenhoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'atividades',
    loadChildren: () => import("./atividades/atividades.module").then(m => m.AtividadesModule)
  },
  {
    path: 'ao-vivo',
    loadChildren: () => import("./ao-vivo/ao-vivo.module").then(m => m.AoVivoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'disciplina/:id',
    loadChildren: () => import("./disciplina/disciplina.module").then(m => m.DisciplinaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'esquecisenha',
    component: EsqueceuSenhaComponent
  },
  {
    path: 'perfil',
    loadChildren: () => import("./perfil/perfil-routing.module").then(m => m.PerfilRoutingModule),
  },
  {
    path: 'notas/registro',
    component: ProfessorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'alunos',
    component: AlunoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'alunos/visao/:id',
    component: AlunoIndividualComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'aluno/notas',
    component: AlunoNotasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'turmas/visao',
    component: TurmaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'enturmacao',
    component: EnturmacaoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'esforco',
    component: TurmaEsforcoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'performance',
    component: PerformanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'agenda',
    component: AgendaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ LoginGuard ]
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
