<div class="container">
    <div class="row col-md-4 mx-auto box-login">
        <div>
            <p class="titulo-login">Recuperação de conta</p>
            <p class="texto-recupera-senha">Digite seu email de acesso que enviaremos um link para definir uma nova
                senha.</p>

            <input class="input mb-2" type="text" placeholder="Informe seu e-mail">
            <div class="d-grid gap-2 col-6 mx-auto div-btn-enviar-email">
                <button class="btn btn-enviar-email" type="button">Entrar</button>
            </div>
        </div>
    </div>
</div>