<main>
  <div class="container">
    <h3 class="titulo-filtro">Filtre sua busca</h3>

    <section class="d-flex flex-wrap filtro-busca" style="justify-content: flex-start; padding-left: 20px;">

      <div class="d-flex mb-3 mt-3">
        <p class="tag-select mr-2">Turma:</p>
        <select (change)="filter()" [(ngModel)]="schoolPackageId"
          class="atividades-contagem-filtro text-select tag-select mr-2" style="width:150px;">
          <option value="0" class="text-select">Escolha uma turma:</option>
          <option [value]="sp.id" *ngFor="let sp of schoolPackages" class="text-select">{{sp.name}}</option>
        </select>
      </div>

      <div class="d-flex mb-3 mt-3" style="margin-left: 1rem;">
        <p class="tag-select mr-2">Período:</p>
        <select (change)="filter()"
            [(ngModel)]="periodId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:300px;">
          <option value="0" class="text-select">Todos</option>
          <option [value]="p.id" *ngFor="let p of periods" class="text-select">{{p.name}}</option>
        </select>
      </div>

      <div class="d-flex mb-3 mt-3" style="margin-left: 1rem;">
        <p class="tag-select mr-2">Plataforma:</p>
        <select (change)="filter()"
                [(ngModel)]="platformId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:300px;">
          <option [value]="p.id" *ngFor="let p of platformSelect" class="text-select">{{p.name}}</option>
        </select>
      </div>

    </section>

    <section *ngIf="isLoading === false">
      <div class="container scroll-table" *ngFor="let p of filteredPeriods">
        <div class="row text-center flex-nowrap">
          <table class="table table-striped table-hover mt-5">
            <thead>
              <tr>
                <th scope="col">{{ p.name }}</th>
                <th scope="col" *ngFor="let c of filteredCategories">{{ c.shortName }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let psp of profileSchoolPackages">
                <td>{{ ( psp.profile.name + ' ' + psp.profile.lastName).toUpperCase() }}</td>
                <td *ngFor="let c of filteredCategories">
                  {{ getObjEsforco(p, psp.profileId, c.id) }}
                </td>
              </tr>
              <tr>
                <th>Percentual da Turma</th>
                <th [attr.colspan]="filteredCategories.length - 1"></th>
                <th>{{ getObjEsforcoTurma(p) }}</th>
              </tr>
              <!-- <tr>
                <td>{{ (profileSchoolPackages[0].profile.name + ' ' + profileSchoolPackages[0].profile.lastName).toUpperCase() }}</td>
                <td>
                  {{ getObjEsforco(p, profileSchoolPackages[0].profileId, 7) }}
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</main>
