import { AutenticacaoService } from './login/service/autenticacao.service';
import { Component, Output, OnInit } from '@angular/core';
import { CrossStorageHub } from 'cross-storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'hub-irium';

  mostrarMenu: boolean = false;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  constructor(private autenticacaoService: AutenticacaoService){

  }

  onStarted() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  onCompleted() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  ngOnInit() {
    this.autenticacaoService.userSubject.subscribe(
      user =>  this.mostrarMenu = (user != null)
    );

    CrossStorageHub.init([
      {origin: /:\/\/(www\.)?hubplataforma.azurewebsites.net$/, allow: ['get','set','del']},
      {origin: /:\/\/(www\.)?iclassroom.com.br$/, allow: ['get','set','del']}
    ]);
  }

}
