import { StudentFilter } from './../../aluno/model/student.filter.model';
import { Student } from './../../aluno/model/student.model';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ProfileSchoolPackage } from '../model/profileSchoolPackage.model';
import { empty, Observable } from 'rxjs';
import { Profile } from '../model/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileSchoolPackageService {

  constructor(private http: HttpClient) {}

  findPerProfileId(profileId: number): Observable<ProfileSchoolPackage[]> {
    if(!profileId) {
      return empty();
    }
    return this.http
               .get<ProfileSchoolPackage[]>(environment.API_PATH + 'core/profileschoolpackages/info/' + profileId);
  }

  findStudentsPerTeacherId(teacherId: number): Observable<any[]> {
    if(!teacherId) {
      return empty();
    }
    return this.http
               .get<Student[]>(environment.API_PATH + 'core/profileschoolpackages/perteacher/' + teacherId);
  }

  findFiltered(params: StudentFilter): Observable<Student[]> {
    return this.http
               .post<Student[]>(environment.API_PATH + 'core/profileschoolpackages/filter', params);
  }

  findPagedPerPackage(packageId: number, page: number): Observable<ProfileSchoolPackage[]> {
    return this.http
               .get<ProfileSchoolPackage[]>(environment.API_PATH + 'core/students/perschoolpackage/' + packageId + '/' + page);
  }

  optVersionIn(profileId: number): Observable<any> {
    return this.http
               .post<any>(environment.API_PATH + 'core/profiles/optactive/' + profileId + '/true', {});
  }

}
