<div class="container mt-4" *ngIf="isLoading === false">
  <div class="card-group">
    <div class="card">

      <div class="card-body text-center">
        <div role="progressbar" [attr.aria-valuenow]="performance" aria-valuemin="0" aria-valuemax="100" [style]="'--value:' + performance">
          <div>
            <span class="estatistica_numeracao">{{ performance }}</span> <span class="card-text estatistica_texto">%</span>
            <h5 class="card-title estatitica_titulo" title="Média das notas das atividades realizadas">Performance</h5>
          </div>
        </div>
      </div>

    </div>
    <div class="card">

      <div class="card-body text-center">
        <div role="progressbar" [attr.aria-valuenow]="participation" aria-valuemin="0" aria-valuemax="100" [style]="'--value:' + participation">
          <div>
            <span class="estatistica_numeracao">{{ participation }}</span> <span class="card-text estatistica_texto">%</span>
            <h5 class="card-title estatitica_titulo" title="Percentual das atividades realizadas que estão ou passaram do prazo">Participação</h5>
          </div>
        </div>
      </div>

    </div>
    <div class="card">

      <div class="card-body text-center">
        <div role="progressbar" [attr.aria-valuenow]="proficiency" aria-valuemin="0" aria-valuemax="100" [style]="'--value:' + proficiency">
          <div>
            <span class="estatistica_numeracao">{{ proficiency }}</span> <span class="card-text estatistica_texto">%</span>
            <h5 class="card-title estatitica_titulo" title="Produto da performance com a participação">Proficiência</h5>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
<hr>
<div class="container" *ngIf="isLoading === false">
  <section class="d-flex flex-wrap filtro-busca">

    <div class="d-flex mb-3 mt-3">
      <p class="tag-select mr-2">Plataforma:</p>
      <select [(ngModel)]="platformId" (change)="changePlatform()" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:300px;">
        <option [value]="p.id" *ngFor="let p of platformSelect" class="text-select">{{p.name}}</option>
      </select>
    </div>

    <div class="d-flex mb-3 mt-3" style="margin-left: 1rem;">
      <p class="tag-select mr-2">Período:</p>
      <select (change)="this.statusId = 0; changeStatus()"
        [(ngModel)]="periodId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:300px;">
        <option value="0" class="text-select">Todos</option>
        <option [value]="p.id" *ngFor="let p of periods" class="text-select">{{p.name}}</option>
      </select>
    </div>

    <div class="d-flex mb-3 mt-3">
      <p class="tag-select mr-2">Status:</p>
      <select (change)="changeStatus()"
        [(ngModel)]="statusId" class="atividades-contagem-filtro text-select tag-select mr-2" style="width:300px;">
        <option value="0" class="text-select" selected>Propostas</option>
        <option value="1" class="text-select">Realizadas</option>
        <option value="2" class="text-select">Não Realizadas</option>
      </select>
    </div>

  </section>
</div>
<hr>

<div class="container scroll-table">
  <div class="row text-center flex-nowrap" *ngIf="isLoading === false">
    <table class="table table-striped table-hover mt-2">
      <thead>
        <tr>
          <th scope="col">Plataforma</th>
          <th scope="col">Lista</th>
          <th scope="col">Período</th>
          <th scope="col">Data Limite</th>
          <th scope="col">Nota</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of activities">
          <td>{{ (platformId === 57) ? 'Edufit' : 'Connecting English' }}</td>
          <td>{{ a.title }}</td>
          <td>{{ getActivityPeriod(a) }}</td>
          <td>{{ a.dueDate | date: 'dd/MM/yyyy' }}</td>
          <td>{{ ( getActivityRecord(a)?.gradeNumber !== null ) ? getActivityRecord(a)?.gradeNumber : ' - ' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
