<div class="container-fluid navegacao-div">
    <nav class="navbar navegacao container ">
        <span class="navbar-brand mb-0 h1">iClassroom</span>
        <button class="btn btn-primary navegacao-botao" [routerLink]="['/login']">Entrar</button>
    </nav>
</div>

<div class="container-fluid banner">
    <div class="row">
        <div class="col-md-6 banner-textos">
            <h5 class="banner-subtitulo">O seu hub educacional digital</h5>
            <h2 class="banner-titulo">
                A partir do iClassroom você consegue acessar todos os produtos digitais da IRIUM Educação
            </h2>
            <p>
              Todas as plataformas com videoteca, livros em pdf, projeto bilíngue, projeto socioemocional, clube do livro
              virtual estão integradas por um login único e podem ser acessadas pelo iClassroom
            </p>
            <button class="btn btn-primary banner-botao" onclick="location.href='https://www.irium.com.br/';">
              Conheça todos os produtos da IRIUM Educação
            </button>
        </div>
        <div class="col-md-6 banner-img">

        </div>
    </div>
</div>

<div class="container-fluid text-center">
  <div class="row sessao-numeros">
      <div class="col-md-3">
          <p class="sessao-numeros-titulo">+400</p>
          <p class="sessao-numeros-texto">Textos para leitura</p>
      </div>
      <div class="col-md-3">
          <p class="sessao-numeros-titulo">+800</p>
          <p class="sessao-numeros-texto">Videoaulas disponíveis</p>
      </div>
      <div class="col-md-3">
          <p class="sessao-numeros-titulo">+15000</p>
          <p class="sessao-numeros-texto">Exercícios propostos</p>
      </div>
      <div class="col-md-3">
          <p class="sessao-numeros-titulo">100%</p>
          <p class="sessao-numeros-texto borderless">das aulas a um click</p>
      </div>
  </div>
</div>

<div class="info">
  <div class="text-center w-50 mx-auto info-titulos">
      <p class="info-titulo">Por que escolher o iClassroom, da IRIUM Educação?</p>
      <h2>Veja os principais diferenciais da nossa plataforma</h2>
  </div>

  <div class="card-group info-cards">
      <div class="card">
          <div class=" info-icon" >
              <fa-icon [icon]="faUser" style="color: #fff;"></fa-icon>
          </div>
          <div class="card-body">
              <h5 class="card-title">Acesso único</h5>
              <p class="card-text">
                O hub permite que você acesse todos os nossos conteúdos e plataformas por um único lugar
              </p>
          </div>

      </div>
      <div class="card">
           <div class=" info-icon" >
              <fa-icon [icon]="faBook" style="color: #fff;"></fa-icon>
          </div>
          <div class="card-body">
              <h5 class="card-title">Acompanhamento</h5>
              <p class="card-text">
                Estudantes e responsáveis conseguem acompanhar a execução e a performance
              </p>
          </div>

      </div>
      <div class="card">
           <div class=" info-icon" >
              <fa-icon [icon]="faCalendar" style="color: #fff;"></fa-icon>
          </div>
          <div class="card-body">
              <h5 class="card-title">Agenda</h5>
              <p class="card-text">
                A agenda de tarefas mantém estudantes e responsáveis cientes do andamento dos estudos
              </p>
          </div>

      </div>
  </div>

</div>


<!-- <div class="container-fluid info">
    <h2 class="text-center mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2>
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="card info-card">
                    <div class="card-body ">
                        <p class="card-title info-card-titulo">Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                            Laudantium sit
                            reiciendis totam iusto:</p>
                        <div class="row">
                            <div class="card info-card-secundario col-md-6" style="width: 18rem;">
                                <div class="card-body">

                                    <fa-icon [icon]="faHeadset" class="info--icones"></fa-icon>
                                    <h4 class="card-title">Special title treatment</h4>
                                    <p class="card-text ">With supporting text below as a natural lead-in to additional
                                        content.</p>

                                </div>

                            </div>
                            <div class="card info-card-secundario col-md-6" style="width: 18rem;">
                                <div class="card-body">
                                    <fa-icon [icon]="faBook" class="info--icones"></fa-icon>
                                    <h4 class="card-title">Special title treatment</h4>
                                    <p class="card-text ">With supporting text below as a natural lead-in to additional
                                        content.</p>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-4">
                <div class="card info-card">
                    <div class="card-body">
                        <h4 class="card-title">Special title treatment</h4>
                        <div class="card info-card-list" style="width: 18rem;">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"><span>
                                        <fa-icon [icon]="faCheck" class="pe-2"></fa-icon>
                                    </span>Cras justo odio</li>
                                <li class="list-group-item"><span>
                                        <fa-icon [icon]="faCheck" class="pe-2"></fa-icon>
                                    </span>Dapibus ac facilisis in</li>
                                <li class="list-group-item"><span>
                                        <fa-icon [icon]="faCheck" class="pe-2"></fa-icon>
                                    </span>Vestibulum at eros</li>
                                <li class="list-group-item"><span>
                                        <fa-icon [icon]="faCheck" class="pe-2"></fa-icon>
                                    </span>Vestibulum at eros</li>
                                <li class="list-group-item"><span>
                                        <fa-icon [icon]="faCheck" class="pe-2"></fa-icon>
                                    </span>Vestibulum at eros</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid banner">
    <div class="row">

        <div class="col-md-8 banner-img-footer"></div>

        <div class="col-md-4">
            <h2 class="banner-titulo">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h2>
            <p class="banner-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus inventore cupiditate iste sunt, sint
                maiores, animi, dolorem amet aperiam voluptate quam illum provident minus cum qui. Consectetur, fuga
                eum. Odio!</p>

        </div>

    </div>
</div> -->
